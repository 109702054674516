<template>
    <!-- Header  -->
    <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Main content  -->
  <div class="container-fluid mt-4" v-if="show">
    <!-- Journey Call to Actions -->
    <div class="row" >
      <div class="col-lg-4 col-md-6 mb-4">
                <place-holder-card
                  :title="{ text: 'Create Journey', variant: 'h5'}"
                  url="/journey/new"
                  color="success"
                />
      </div>
      <div class="col-lg-4 col-md-6 mb-4">
                <place-holder-card
                  :title="{ text: 'Checkout our pre-defined journeys', variant: 'h5' }"
                  url="#templates"
                  color="info"
                />
      </div>
      <div class="col-lg-4 col-md-6 mb-4">
                <place-holder-card
                  :title="{ text: 'Use Other Therapists Templates', variant: 'h5' }"
                  url="javascript::"
                  color="primary"
                  @click="popup = true"
                />
      </div>
    </div>
    <hr class="hr">
    <!-- Last 3 Journeys  -->
    <div class="row mb-4" id="userJourneys">
      <AllJourneysView title="Your Journeys" :journeys="therapist.created_journeys" btnClass="success"/>
    </div>

    <!-- List of Journeys -->
    <div class="row" id="templates">
      <AllJourneysView title="Journey Templates" :journeys="journeys" btnClass="secondary"/>
    </div>

    <!-- popup  -->
    <Popup v-if="popup"
    title="This feature is coming soon!"
    description="Our dedicated team is working around the clock to deliver more features and tool kits for therapists. Each day we will deploy new features. Thank you for being with us in this journey."
    @close="popup = false"
     />

  </div>
</template>

<script>
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import AllJourneysView from "./components/AllJourneysView.vue";
import axios from 'axios';
import Popup from './components/Popup.vue'
import {mapGetters } from "vuex";

export default {
  name: "Journeys",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      journeys: [],
      therapist:[],
      popup: false,
      show: false,
    }
  },
  components: {
    PlaceHolderCard,
    AllJourneysView,
    Popup
  },
  methods: {
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
    });
    await this.fetchData('/therapy/api/template_journeys/').then((journeys)=>{
      this.journeys = journeys;
    });
    this.show=true;
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  computed: {
  ...mapGetters(['getToken', 'getRToken', 'getUser']),
}
};
</script>
