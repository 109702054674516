<template>
  <div class="card h-100" style="background-color: #e0f5b8;">
    <div class="p-3 card-body">
      <h6>Create New Assignment</h6>
      <form @submit.prevent="addAssignment" class="g-3">
        <div>
          <label for="title" class="form-label">Title:</label>
          <input type="text" id="title" v-model="newAssignment.title" class="form-control" required>
        </div>
        <div>
          <label for="description" class="form-label">Description:</label>
          <textarea id="description" v-model="newAssignment.description" class="form-control" required></textarea>
        </div>
        <div class="col-12 mt-3">
          <button type="submit" class="btn btn-success">Create Assignment</button>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AssignmentForm",
  data() {
    return {
      newAssignment: {
        title: '',
        description: '',
        status: 'incomplete'
      }
    };
  },
  methods: {
    addAssignment() {
      if (this.newAssignment.title.trim() === '' || this.newAssignment.description.trim() === '') {
        alert('Please fill out all fields.');
        return;
      }
      // Push the new task to the tasks array or dispatch an action to a Vuex store
      this.$emit('new-assignment', this.newAssignment);

      // Clear the form fields after submitting
      this.newAssignment.title = '';
      this.newAssignment.description = '';
      this.newAssignment.id = "";
    }
  }
};
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>