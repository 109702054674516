<template>
  <div class="editor" v-if="editor">
    <menu-bar class="editor__header" :editor="editor" />
    <editor-content class="editor__content" :editor="editor"/>
    <div class="editor__footer">
      <div class="editor__name">
        <button>
          Author: {{ currentUser.first_name }}
        </button>
        <button @click="saveContent">
            Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import MenuBar from './TipTapMenuBar.vue'



export default {
  components: {
    EditorContent,
    MenuBar,
  },
  data() {
    return {
      editor: null,
      editorContent: '', // Bind the editor content to a data property  
    }
  },
  props: {
    currentUser: Object,
    note: Object,
    },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        Highlight,
        TaskList,
        TaskItem,
        CharacterCount.configure({
          limit: 10000,
        }),
        
      ],
      content: this.note.content == '' || this.note.content == '<p></p>' || this.note.content == 'default' ? `<h2>Guideline: Taking Notes in Therapy Sessions</h2>
        
        <h3>Introduction</h3>
        <p>Taking notes during therapy sessions can be a valuable tool for both therapists and clients. It helps in capturing important insights, tracking progress, and facilitating deeper reflection.</p>
        
        <h3>Guidelines for Taking Effective Notes</h3>
        <ul>
            <li><strong>Stay Focused:</strong> Pay close attention to what is being discussed and jot down key points, insights, and reflections.</li>
            <li><strong>Be Objective:</strong> Record information objectively without judgment or bias. Use neutral language to describe thoughts, feelings, and behaviors.</li>
            <li><strong>Use Abbreviations:</strong> Develop a system of abbreviations to quickly capture information. This can save time and help in keeping up with the flow of conversation.</li>
            <li><strong>Organize Notes:</strong> Use headings, bullet points, or numbering to organize your notes in a structured manner. This makes it easier to review and reference them later.</li>
            <li><strong>Include Client's Feedback:</strong> Record any feedback, questions, or observations shared by the client during the session. This encourages active participation and collaboration.</li>
            <li><strong>Respect Confidentiality:</strong> Ensure that your notes are kept confidential and stored securely. Avoid including any identifying information unless necessary for treatment purposes.</li>
        </ul>`: this.note.content,
    });
    // Listen to editor content changes
    this.editor.on('update', ({ editor }) => {
      this.editorContent = editor.getHTML();
    });
  },

  methods: {
    saveContent(){
        this.$emit('content', this.editorContent)
    }
  },
  watch: {
    note: {
      immediate: true, // Run the handler immediately when the component is created
      handler(newNote) {
        if (newNote && this.editor) {
          // If a new note is provided and the editor is initialized
          this.editor.commands.setContent(newNote); // Set the content of the editor
        }
      },
    },
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.editor:focus{
    outline: none !important;
}
.editor {
  background-color: #FFF;
  border: 3px solid #31da56;
  border-radius: 0.75rem;
  color: #0D0D0D;
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  min-height: 26rem;
  outline: none;

  &__header {
    align-items: center;
    background: #31da56;
    border-bottom: 3px solid #31da56;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.25rem 1rem;
    -webkit-overflow-scrolling: touch;
    outline: none;
  }

  &__footer {
    align-items: center;
    border-top: 3px solid #31da56;
    color: #0D0D0D;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    white-space: nowrap;
  }

  /* Some information about the status */


  &__name {
    button {
      background: #0d0d0d;
      border: none;
      border-radius: 0.4rem;
      color: #ffffff;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      margin-right: 1rem;

      &:hover {
        background-color: #2ebc06;
        color: #FFF;
      }
    }
  }
}

/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    border-radius: 0.5rem;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    border-left: 2px solid rgba(#0D0D0D, 0.1);
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      align-items: center;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}
.tiptap.ProseMirror{
    outline: none  !important;
    height: fit-content;
} 
.tiptap.ProseMirror:focus{
    outline: none  !important;
}
.tiptap.ProseMirror.ProseMirror-focused {
    outline: none !important;
}
</style>