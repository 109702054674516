<template>
    <div class="popup-overlay">
      <div class="popup-content">
        <div class="popup-header">
          <h2>{{title}}</h2>
          <button @click="closePopup" class="close-btn">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="popup-body">
          <p>{{ description }}</p>
        </div>
        <div class="popup-footer">
          <button @click="confirmMessage" class="btn" :class="'btn-'+color">{{confirmButtonText}}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      closePopup() {
        this.$emit('close');
      },
      confirmMessage() {
        this.$emit('confirm');
      },
    },
    props: {
        title: String,
        description: String,
        color: {
          type: String,
          default: 'success'
        },
        confirmButtonText: {
          type: String,
          default: 'Sure!'
        },
    }
  }
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    padding: 20px;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .popup-header h2 {
    margin: 0;
  }
  
  .close-btn {
    border: none;
    background: none;
    cursor: pointer;
    color: #999;
    font-size: 1.5em;
  }
  
  .popup-body {
    margin-bottom: 20px;
  }
  
  .popup-footer {
    text-align: right;
  }
  
  </style>
  