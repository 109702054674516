<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Main    -->
  <div class="container-fluid mt-4">
    <h5>Create your own journey</h5>
    <hr>

    <!-- STEP 1 -->
    <form role="form" class="text-start" @submit.prevent="createJourneyInfo">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4">
              <label for="title" class="form-label">Choose a title for your journey</label>
              <input type="text" id="title" v-model="journey.name" class="form-control" required :disabled="step != 1">
            </div>          
            <div class="col-lg-4">
              <label>Which disorder is your main focus for this journey?</label>
                <select v-model="journey.disorder" class="form-select form-select-border-radius-3" :disabled="step != 1">
                  <option selected disabled :value="journey.disorder_data ? journey.disorder_data : ''">
                    {{journey.disorder_data ? journey.disorder_data.name : 'None'}}
                  </option>
                  <option v-for="disorder in disorders" :key="disorder" :value="disorder.id">{{disorder.name}}</option>
                </select>
            </div>
            <div class="col-lg-4">
              <label>Which approach is used as main approach?</label>
                <select v-model="journey.approach" class="form-select form-select-border-radius-3" :disabled="step != 1">
                  <option selected disabled :value="journey.approach_data ? journey.approach_data : ''">
                    {{journey.approach_data ? journey.approach_data.name : 'None'}}
                  </option>
                  <option v-for="approach in approaches" :key="approach" :value="approach.id">{{approach.name}}</option>
                </select>
            </div>
          </div>
        </div>
      </div>

    
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">
              <label for="description" class="form-label">Introduce this journey in less than 100 words:</label>
              <textarea type="text" id="description" v-model="journey.description" class="form-control" required :disabled="step != 1"></textarea>
            </div>          
          </div>
          <div class="col-lg-4 mt-2" v-if="showFinalMessage">
            <soft-alert :color="finalMessageType">
              {{finalMessage}}
            </soft-alert>
          </div>
          <div class="col-lg-6 inline mt-5" v-if="step == 1">
            <router-link class="btn bg-gradient-info m-1" :to="{ name: 'Journeys'}">
              <i class="fa fa-arrow-left"></i> Back to Templates
            </router-link>
            <button
                class="btn bg-gradient-success m-1"
                >Save & Continue <i class="fa fa-arrow-right"></i></button
              >
          </div>
        </div>
      </div>
    </form>
    

  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import axios from 'axios';
import SoftAlert from "@/components/SoftAlert.vue"


export default {
  name: "Edit Journey",
  order: 0,
  data() {
    return {
      step: 1,
      therapist: {},
      iconBackground: "bg-gradient-success",
      journey: {
          "name": "",
          "description": "",
          "template": true,
          "user": null,
          "disorder": null,
          "approach": null
      },
      showFinalMessage: false,
      finalMessage: '',
      finalMessageType: '',
      approaches: {},
      disorders: {},


    }
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
      this.journey.user= this.therapist.user.id
    });
    await this.fetchData('/therapy/api/approaches/').then((approaches)=>{
      this.approaches = approaches;
    });
    await this.fetchData('/therapy/api/disorders/').then((disorders)=>{
      this.disorders = disorders;
    });

  },
  components: {
    SoftAlert,

  },
  methods: {
    async fetchData(target) {
        let nextPage = target; // Replace this with your API endpoint
        let variable = [];

        try {
          while (nextPage) {
            const response = await axios.get(nextPage);
            const data = response.data;

            // Append results from current page to allInstances
            variable = variable.concat(data.results);

            // Update nextPage with the URL of the next page, if it exists
            nextPage = data.next;
          }

          // At this point, allInstances will contain all fetched instances
          return variable;
          
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },
    async getJourney(id){
      try {
        const response= await axios.get(`/therapy/api/journeys/${id}/`);
        this.journey = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
          throw error;
      }
    },
    async createJourneyInfo(){
      this.step++
      try {
          const response= await axios.post(`/therapy/api/journeys/`, this.journey);
          this.getJourney(response.data.id);
          this.showFinalMessage= true;
          this.finalMessage="Journey created successfully."
          this.finalMessageType= 'success'
          setTimeout(() => {
            this.showFinalMessage= false;
            this.$router.push({name: 'Edit Journey', params: {id: this.journey.id}})
          }, 2000);

        } catch (error) {
          this.showFinalMessage= true;
          this.finalMessage="Error: "+ error.response.data
          this.finalMessageType= 'danger'
          setTimeout(() => {
            this.showFinalMessage= false;
          }, 3000);
        }

    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },

};
</script>
