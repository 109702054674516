<template>
    <div class="container text-center mt-5">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <iframe height="400" src="https://lottie.host/embed/663ee6c4-1920-47f6-bb91-60466e047939/AKKQ6ruIK6.json"></iframe>
      <div>
        <a type="button" class="btn btn-warning bg-gradient-warning" @click.prevent="$router.push({ name: 'Dashboard' })">Back to Dashboard</a>
      </div>
      
    </div>
  </template>
  
  <script>
  const body = document.getElementsByTagName("body")[0];
  import { mapMutations } from 'vuex';
  export default {
    name: 'NotFound',
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
  };
  </script>