<template>
  <!-- Navbar -->
  <nav
    class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
    :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
  >
    <div class="container">
      <router-link
        class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
        to="/"
        v-bind="$attrs"
        :class="isBlur ? 'text-dark' : 'text-white'"
      >
        Therapistory
      </router-link>
      <button
        class="navbar-toggler shadow-none ms-2"
        type="button"
         @click="toggled = !toggled"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon mt-2">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" :class="{ 'show': toggled }" id="navigation">
        <ul class="nav navbar-nav mx-auto">
          <li class="nav-item" v-if="token != null">
            <router-link
              
              class="nav-link d-flex align-items-center me-2 active"
              aria-current="page"
              to="/dashboard"
            >
              <i
                class="fa fa-chart-pie opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Dashboard
            </router-link>
          </li>
          <li class="nav-item"  v-if="token == null">
            <router-link class="nav-link me-2" to="/sign-up">
              <i
                class="fas fa-user-circle opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Sign Up
            </router-link>
          </li>
          <li class="nav-item" v-if="token == null">
            <router-link class="nav-link me-2" to="/sign-in" >
              <i
                class="fas fa-key opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
              Sign In
            </router-link>
          </li>
          <li class="nav-item" v-if="token !== null">
            <a type="button" class="nav-link me-2" @click.prevent="signOut" >
              <i
                class="fas fa-sign-out-alt opacity-6 me-1"
                aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"
              ></i>
             Sign Out
            </a>
          </li>
        </ul>
        <ul class="navbar-nav d-lg-block d-none">
          <li class="nav-item">
            <a
              href="https://nahan.uk/"
              class="btn btn-sm btn-round mb-0 me-1"
              :class="isBlur ? 'bg-gradient-dark' : 'bg-gradient-success'"
              >Nahan Management</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import { mapGetters,  mapActions } from 'vuex';

export default {
  name: "navbar",
  data() {
    return {
      downArrWhite,
      downArrBlack,
      toggled: false,
      toke: null,
    };
  },
  props: {
    btnBackground: String,
    isBlur: String,
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.token= this.getAccessToken
  },
  methods: {
    ...mapActions(["toggleSidebarColor", "clearTokens", "logout"]),
    signOut() {
      setTimeout(() => this.clearTokens().then(() => {
        this.$router.push({ name: 'Sign In' });
      }), 500);

      // // this.logout();
      // setTimeout(() => {this.$router.push({ name: 'Sign In' });}, 2000);

    },
  },
  computed: {
    ...mapGetters(['getAccessToken', 'getRefreshToken', 'getUser']),
  },
};
</script>
