<template>
  <!-- Preloader  -->
  <div class="container-fluid py-4 text-center" v-if="!showContent">
    <Spinner />
  </div>

  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Main  -->
  <div class="py-4 container-fluid" v-if="showContent">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Total # of Clients"
          :value="therapist.clients.length"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="# of Your Journeys"
          :value="therapist.created_journeys.length"
          :icon="{
            component: 'ni ni-spaceship',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <a
            class="btn bg-gradient-success mt-4 w-100"
            href="/clients/new"
            type="button"
            >+ Add new client</a
          >
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <a
            class="btn bg-gradient-success mt-4 w-100"
            href="/journey/new"
            type="button"
            >+ Create a new Journey</a
          >
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <AllClientsDashboard :clients="therapist.clients" :journeys="therapist.created_journeys"/>
      </div>
      <div class="col-lg-4 col-md-6 mb-md-0 mb-4" v-if="therapist.created_journeys != 0">
        <JourneyCard :journey="therapist.created_journeys[therapist.created_journeys.length - 1]" :phases="null"/>
      </div>
      <div class="col-lg-4 col-md-6 mb-md-0 mb-4" v-else>
        <PlaceHolderCard
                  :title="{ text: 'Create a Journey', variant: 'h5'}"
                  url="/journey/new"
                  color="success"
         />
      </div>

    </div>

    <!-- News Feed  -->
    <div class="row">
      <div class="col-lg-7">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://picsum.photos/seed/mount/1080/720');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  APA's recent article you might like
                </h5>
                <p class="text-white mb-5">
                  Here’s advice from psychologists on how to help kids cope with anger and frustration
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  href="https://www.apa.org/topics/parenting/managing-preschool-aggression"
                >
                  Read more
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://picsum.photos/seed/background/1080/720');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  PBS Report
                </h5>
                <p class="text-white mb-5">
                  Developing chemsex intervention services: Guidance for psychological care provision
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  href="https://explore.bps.org.uk/content/report-guideline/bpsrep.2023.bre59"
                >
                  Read more
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import AllClientsDashboard from "./components/AllClientsDashboard.vue";
import JourneyCard from "./components/JourneyCard.vue";
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
import Spinner from './components/Preloader.vue';
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";


export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      therapist: [],
      clients:[],
      todos:[],
      userJourneys: [],
      clientSessions: [],
      showContent: false,
    
    };
  },
  components: {
    MiniStatisticsCard,
    AllClientsDashboard,
    JourneyCard,
    Spinner,
    PlaceHolderCard
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
    })
    setTimeout(() => {
      this.showContent = true
    }, 200);
  },
  computed: {
      ...mapGetters(['getAccessToken', 'getRefreshToken', 'getUser']),
  },
  methods: {
    ...mapActions(['setToken', 'login', 'clearTokens']),
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/therapy/api/therapists/`)
      .then(response => {
        // Check if the response status indicates permission-denied
        if (response.status === 403 || response.status === 404) {
          // Redirect to 404 page
          next({ name: 'NotFound' });
        } else {
          // Proceed to enter the route
          next();
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Redirect to 404 page
        next({ name: 'NotFound' });
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
