<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <!-- Clients Call to Actions -->
    <div class="row">
      <div class="col-lg-4 col-md-6 mb-4">
                <place-holder-card
                  :title="{ text: 'Add a new client', variant: 'h5' }"
                  url="/clients/new"
                  color="success"
                />
      </div>
    </div>
    <hr class="hr">
    <div class="row">
      <div class="col-12">
        <clients-table />
      </div>
    </div>
  </div>
</template>

<script>
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import ClientsTable from "./components/ClientsTable";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "clients",
  components: {
    ClientsTable,
    PlaceHolderCard

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
