<template>
    <div class="card h-100 mb-4">
      <div class="card-header pb-0">
        <div style="margin-left: 1rem;">
          <a href="javascript::" :class="'btn btn-'+btnClass">{{title}}</a>
          <p class="text-sm text-start">you can search current templates based on approaches, disorders and titles</p>
        </div>
        
        <div class="row" style="margin-left: 1rem;">
            <input
            id="search"
            type="text"
            class="form-control"
            style="width: auto; height: fit-content;"
            name="search"
            v-model="searchQuery"
            placeholder="search journeys ..."
            
          />
          <div class="col-md-3">
              <select v-model="showPerPage" class="form-select form-select-border-radius-3">
                <option active selected value=10>10 Journeys Per Page</option>
                <option value=50> 50 Journeys Per Page </option>
              </select>
          </div>
          <div class="col-lg-3">
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><i class="fa fa-arrow-left" style="color: green;"></i></button>
                </li>
                <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                  <button class="page-link" @click="changePage(pageNumber)">{{ pageNumber }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="page-link" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><i class="fa fa-arrow-right" style="color: green;"></i></button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0" v-if="journeys.length > 0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Journey Title
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Main Method
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Use Cases
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Is a Template
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="journey in paginatedJourneys" :key="journey" >
                
                <td style="max-width: 300px; overflow: hidden; text-overflow: ellipsis; white-space: normal;">
                  <div class="d-flex px-2 py-1">
                    <div>
                      <a :href="'/journeys/'+ journey.id">
                      <soft-avatar
                        :img="'https://api.dicebear.com/7.x/shapes/svg?seed='+journey.name"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="{{ journey.name }}"
                      /></a>
                    </div>
                    <div class="d-flex flex-column justify-content-center text-sm">
                      <a :href="'/journeys/'+ journey.id">{{ journey.name }}</a>
                      <div class="text-xs"># of phases: {{ journey.phases.length }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center text-xs">
                      {{ journey.approach ? journey.approach_data.name : 'N/A' }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center text-xs">
                      {{ journey.disorder ? journey.disorder_data.name : 'N/A' }}
                    </div>
                  </div>
                </td>
                <td class="align-middle text-xs" :class="journey.template ? 'text-success' : 'text-danger'"> 
                  <i class="fa fa-atom"  aria-hidden="true"></i> 
                  {{journey.template ? 'Yes' : 'No'}}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="journeys.length == 0" class="card col-lg-10 row h-100 align-items-center text-center justify-center">
            <div class="card-body col-lg-6 text-success text-gradient"><h6>You have not created a journey yet... Give it a try, you may help others by creating structured journeys.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import SoftAvatar from "@/components/SoftAvatar.vue";

export default {
  name: "AllJourneys",
  data() {
    return {
      searchQuery: "",
      showPerPage: 10, // Default number of journeys to show per page
      currentPage: 1, // Current page number
      selectedJourney: null,
      
    };
  },
  components: {
    SoftAvatar,
  },
  props: {
    btnClass: {
      type: String,
      default: 'success'
    },
    journeys: [],
    title: {
      type: String,
      default: 'All Journeys'
    },
    backButtonText: {
      type: String,
      default: "Back"
    },
  },
  methods: {
    back(){
      this.$emit('back')
    },
    // Change the current page
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleCheckboxChange(journey) {
      if (this.selectedJourney === journey) {
        this.selectedJourney = null; // Unselect the journey if it's already selected
      } else {
        this.selectedJourney = null; // Select the clicked journey
        this.selectedJourney = journey; // Select the clicked journey
      }
    },
},
  computed: {
    filteredJourneys() {
      if (this.searchQuery=="") {
        return this.journeys;
      } else {
        const query = this.searchQuery.toLowerCase();
        return this.journeys.filter(journey => {
          return (
            journey.name.toLowerCase().includes(query) ||
            (journey.approach && journey.approach_data.name.toLowerCase().includes(query)) ||
            (journey.disorder && journey.disorder_data.name.toLowerCase().includes(query))
            // You can add more properties for filtering if needed
          );
        });
      }
    },
    // Calculate total number of pages based on showPerPage and filtered journeys
    totalPages() {
      return Math.ceil(this.filteredJourneys.length / this.showPerPage);
    },
    // Paginate the journeys based on current page and showPerPage
    paginatedJourneys() {
      const startIndex = (this.currentPage - 1) * this.showPerPage;
      const endIndex = startIndex + this.showPerPage;
      return this.filteredJourneys.slice(startIndex, endIndex);
    },
  },
  watch: {
      // Watch for changes in showPerPage and reset currentPage to 1
      showPerPage: function(newShowPerPage, oldShowPerPage) {
        if (newShowPerPage !== oldShowPerPage) {
          this.currentPage = 1;
        }
    }
  },
};
</script>