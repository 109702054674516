<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Main    -->
  <div class="container-fluid mt-4">
    <h5>Edit the Journey</h5>
    <hr>

    <!-- STEP 1 -->
    <form role="form" class="text-start" @submit.prevent="updateJourney">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4">
              <label for="title" class="form-label">Choose a title for your journey</label>
              <input type="text" id="title" v-model="journey.name" class="form-control" required>
            </div>          
            <div class="col-lg-4">
              <label>Which disorder is your main focus for this journey?</label>
                <select v-model="journey.disorder" class="form-select form-select-border-radius-3">
                  <option selected disabled :value="journey.disorder_data ? journey.disorder_data : ''">
                    {{journey.disorder_data ? journey.disorder_data.name : 'None'}}
                  </option>
                  <option v-for="disorder in disorders" :key="disorder" :value="disorder.id">{{disorder.name}}</option>
                </select>
            </div>
            <div class="col-lg-4">
              <label>Which approach is used as main approach?</label>
                <select v-model="journey.approach" class="form-select form-select-border-radius-3">
                  <option selected disabled :value="journey.approach_data ? journey.approach_data : ''">
                    {{journey.approach_data ? journey.approach_data.name : 'None'}}
                  </option>
                  <option v-for="approach in approaches" :key="approach" :value="approach.id">{{approach.name}}</option>
                </select>
            </div>
          </div>
        </div>
      </div>


  <!-- STEP 2 -->
    
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8">
              <label for="description" class="form-label">Introduce this journey in less than 100 words:</label>
              <textarea type="text" id="description" v-model="journey.description" class="form-control" required></textarea>
            </div>          
          </div>
        </div>
      </div>
    
    
    <!-- STEP 3 -->
  
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <h6>Enter all phases of the Journey</h6>
          <label>You can drag and drop phases to sort them as you like</label>
          <div class="p-3 card-body">
            <div class="row d-flex flex-wrap">
              <draggable style="display: contents; " v-model="journey.phases" group="people" @start="drag=true" @end="drag=false" item-key="id">
                <template v-slot:item="{ element }"  >
                  <div class="mt-4 mb-4 col-12 col-md-4">
                    <PhaseFormEdit
                      :index="journey.phases.findIndex(item => item === element)"
                      :phase="element"
                      @remove-phase="removePhase"
                      @update-phase="updatePhase"
                    />
                  </div>
                </template>
              </draggable>
              <div class="mt-4 mb-4 col-12 col-md-4">
                <PhaseForm
                
                @new-phase="addPhase"/>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-2" v-if="showMessage">
            <soft-alert :color="messageType">
              {{message}}
            </soft-alert>
          </div>
          <div class="col-lg-4 mt-2" v-if="showFinalMessage">
            <soft-alert :color="finalMessageType">
              {{finalMessage}}
            </soft-alert>
          </div>
          <div class="col-lg-6 inline mt-5">
            <router-link :to="{ name: 'Journey View', params: { id: journey.id }}" class="btn btn-info m-1">
              Journey Page
            </router-link>
            <button
                class="btn bg-gradient-success m-1"           
                > Update the journey <i class="fa-solid fa-champagne-glasses"></i></button
              >
          </div>
        </div>
      </div>
    </form>
    

  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import PhaseForm from "./components/PhaseForm.vue";
import axios from 'axios';
import SoftAlert from "@/components/SoftAlert.vue"
import draggable from 'vuedraggable';
import PhaseFormEdit from "./components/PhaseFormEdit.vue";

export default {
  name: "Edit Journey",
  order: 0,
  data() {
    return {
      iconBackground: "bg-gradient-success",
      journey: {},
      journeyCreationSuccess: false,
      journeyCreationError: null,
      drag: false,
      showMessage: false,
      showFinalMessage: false,
      finalMessage: '',
      finalMessageType: '',
      message: '',
      messageType: '',
      approaches: {},
      disorders: {}

    }
  },
  async created() {
    await this.getJourney();
    await this.fetchData('/therapy/api/approaches/').then((approaches)=>{
      this.approaches = approaches;
    });
    await this.fetchData('/therapy/api/disorders/').then((disorders)=>{
      this.disorders = disorders;
    });
  },
  components: {
    PhaseForm,
    PhaseFormEdit,
    SoftAlert,
    draggable

  },
  methods: {
    async fetchData(target) {
        let nextPage = target; // Replace this with your API endpoint
        let variable = [];

        try {
          while (nextPage) {
            const response = await axios.get(nextPage);
            const data = response.data;

            // Append results from current page to allInstances
            variable = variable.concat(data.results);

            // Update nextPage with the URL of the next page, if it exists
            nextPage = data.next;
          }

          // At this point, allInstances will contain all fetched instances
          return variable;
          
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },
    async getJourney(){
      try {
        const response= await axios.get(`/therapy/api/journeys/${this.$route.params.id}/`);
        this.journey = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
          throw error;
      }
    },
    async updateJourneyInfo(){
      try {
          await axios.patch(`/therapy/api/journeys/${this.journey.id}/`, this.journey);
          this.getJourney();
          this.showFinalMessage= true;
          this.finalMessage="Journey updated successfully."
          this.finalMessageType= 'success'
          setTimeout(() => {
            this.showFinalMessage= false;
          }, 2000);

        } catch (error) {
          this.showFinalMessage= true;
          this.finalMessage="Error: "+ Object.values(error.response.data)
          this.finalMessageType= 'danger'
          setTimeout(() => {
            this.showFinalMessage= false;
          }, 3000);
        }

    },
    async updatePhases(){
      for (let index = 0; index < this.journey.phases.length; index++) {
        this.journey.phases[index].number = index
      };
      for (let index = 0; index < this.journey.phases.length; index++) {
        this.updatePhase(this.journey.phases[index], this.journey.phases[index].todos)
      };
      await this.getJourney();
      this.showMessage = false;
      this.showFinalMessage= true;
      this.finalMessage="Journey updated successfully."
      this.finalMessageType= 'success'
      setTimeout(() => {
        this.showFinalMessage= false;
        this.$router.push({ name: 'Journey View', params: { id: this.journey.id } });
      }, 2000);
    },
    async updateJourney(){
      try {
          await axios.patch(`/therapy/api/journeys/${this.journey.id}/`, this.journey);
          this.updatePhases();

        } catch (error) {
          this.showMessage= true;
          this.message="Error: "+ Object.values(error.response.data)
          this.messageType= 'danger'
          setTimeout(() => {
            this.showMessage= false;
          }, 3000);
        }

    },
    nextStep(){
      this.step++;
    },
    stepBack(){
      this.step = this.step - 1
    },
    async removePhase(phase) {
      try {
        await axios.delete(`/therapy/api/phases/${phase.id}/`);
        this.getJourney();
        
        this.showMessage= true;
        this.message="Phase removed successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      } catch (error) {
        this.showMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 3000);
      }
    },
    async addPhase(phase, todos){
      let newPhase = {
          "name": phase.name,
          "description": phase.description,
          "journey": this.journey.id,
          "number": this.journey.phases.length
        };
      try {
        const response= await axios.post(`/therapy/api/phases/`, newPhase);
        this.addTodos(response.data, todos)
        

      } catch (error) {
        console.log(error)
        this.showMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 3000);
      }
    },
    async updatePhase(phase, todos){
      try {
        await axios.patch(`/therapy/api/phases/${phase.id}/`, phase);
        this.updateJourneyInfo();
        this.updateTodos(phase, todos);
      
      } catch (error) {
        this.showMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 3000);
      }
    },
    async addTodos(phase, todos){
        for (let item = 0; item < 5; item++){
          const phaseTodo = {
              "priority": item,
              "description": todos[item],
              "completed": false,
              "phase": phase.id
            };
          try {
            await axios.post('/therapy/api/todos/', phaseTodo);
          } catch (error) {
            console.error('Error creating todo:', error);
          }
        };
        this.getJourney();
        this.showMessage= true;
        this.message="Phase created successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
    },
    async updateTodos(phase, todos){
      for (let item = 0; item < 5; item++){
        let phaseTodo = {
            "priority": item,
            "description": todos[item].description,
            "phase": phase.id
          };
        try {
          await axios.patch(`/therapy/api/todos/${todos[item].id}/`, phaseTodo);
        } catch (error) {
          console.error('Error updating todo:', error);
        }
      };
      this.getJourney();
      this.showMessage= true;
      this.message="Phase updated successfully."
      this.messageType= 'success'
      setTimeout(() => {
        this.showMessage= false;
      }, 2000);
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/therapy/api/journeys/${to.params.id}/`)
      .then(response => {
        // Check if the response status indicates permission-denied
        if (response.status === 403 || response.status === 404) {
          // Redirect to 404 page
          next({ name: 'NotFound' });
        } else {
          // Proceed to enter the route
          next();
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Redirect to 404 page
        next({ name: 'NotFound' });
      });
  },

};
</script>
