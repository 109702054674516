<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-150 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Name of Journey</h5>
            <p class="mb-0 text-sm font-weight-bold">Purpose - Method</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#phase1"
                  href="#phase1"
                  role="tab"
                  id="phase1-tab"
                  aria-controls="phase1"
                  aria-selected="true"
                >
                  <span class="ms-1">Phase #1</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#phase2"
                  href="#phase2"
                  role="tab"
                  id="phase2-tab"
                  aria-controls="phase2"
                  aria-selected="true"
                >
                  <span class="ms-1">All Phases</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid tab-content">
    <div class="tab-pane fade show active" id="phase1" role="tabpanel" aria-labelledby="phase1-tab">
        <TabPanel
        :tabs="['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4', 'Tab 5', 'Tab 6', 'Tab 7', 'Tab 8', 'Tab 9', 'Tab 10']"
        :activeTab="activeTab" @tab-change="handleTabChange">
          <!-- Define your tab content here -->
          <transition-group name="slide">
          <div v-if="activeTab === 'Tab 1'" key="tab1" class="tabPanel-content">
            <div class="mt-3 row">
              <!-- Intro -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <JourneyInfoCard
                  title="Introduction"
                  description="This journey has been designed to tackle disorders and help clients to go through a structured and designed journey. Growth should not be a random thing. Let's make it happen."
                  :info="{
                    disorder: 'PTSD',
                    method: 'Reality Therapy',
                    phasesNumber: 10,
                    shared: 'Yes! :)',
                  }"
                  :social="[
                    {
                      link: 'https://www.facebook.com/CreativeTim/',
                      icon: faFacebook,
                    },
                    {
                      link: 'https://twitter.com/creativetim',
                      icon: faTwitter,
                    },
                    {
                      link: 'https://www.instagram.com/creativetimofficial/',
                      icon: faInstagram,
                    },
                  ]"
                  :action="{
                    route: 'javascript:;',
                    tooltip: 'Edit Journey',
                  }"
                />
              </div>

              <!-- ToDo Card -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <TodoCard :todos="[
                  {id:1, text: 'talk to client', status: false},
                  {id:2, text: 'talk to manager', status: false},
                  {id:3, text: 'talk to therapistory support', status: false},
                  {id:4, text: 'complete the assessment', status: false},
                  {id:5, text: 'get a report', status: false},
                  {id:6, text: 'upload the data', status: false},
                  {id:7, text: 'finalize the contract', status: false},
                ]"/>
              </div>

              <!-- RecomCard -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <RecomCard />
              </div>
            </div>  
          </div>
          <div v-if="activeTab === 'Tab 2'" key="tab2" class="tabPanel-content">
            <div class="mt-3 row">
              <!-- Intro -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <JourneyInfoCard
                  title="Introduction"
                  description="This journey has been designed to tackle disorders and help clients to go through a structured and designed journey. Growth should not be a random thing. Let's make it happen."
                  :info="{
                    disorder: 'PTSD',
                    method: 'Reality Therapy',
                    phasesNumber: 10,
                    shared: 'Yes! :)',
                  }"
                  :social="[
                    {
                      link: 'https://www.facebook.com/CreativeTim/',
                      icon: faFacebook,
                    },
                    {
                      link: 'https://twitter.com/creativetim',
                      icon: faTwitter,
                    },
                    {
                      link: 'https://www.instagram.com/creativetimofficial/',
                      icon: faInstagram,
                    },
                  ]"
                  :action="{
                    route: 'javascript:;',
                    tooltip: 'Edit Journey',
                  }"
                />
              </div>

              <!-- ToDo Card -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <TodoCard :todos="[
                  {id:1, text: 'talk to client', status: false},
                  {id:2, text: 'talk to manager', status: false},
                  {id:3, text: 'talk to therapistory support', status: false},
                  {id:4, text: 'complete the assessment', status: false},
                  {id:5, text: 'get a report', status: false},
                  {id:6, text: 'upload the data', status: false},
                  {id:7, text: 'finalize the contract', status: false},
                ]"/>
              </div>

              <!-- RecomCard -->
              <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <RecomCard />
              </div>
            </div>  
          </div>
          <div v-if="activeTab === 'Tab 3'">Tab 3 Content</div>
          </transition-group>
          <!-- Add more tab content as needed -->
        </TabPanel>
    </div>

    <div class="tab-pane fade show" id="phase2" role="tabpanel" aria-labelledby="phase2-tab">
      <div class="mt-4 row">
        <HorizontalTimeline />
      </div>
    </div>
  </div>
</template>

<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
import JourneyInfoCard from "./components/JourneyInfoCard.vue";
// import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import DefaultProjectCard from "./components/DefaultProjectCard.vue";
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import HorizontalTimeline from "./components/HorizontalTimeline.vue";
import TodoCard from "./components/TodoCard.vue"
import RecomCard from "./components/RecomCard.vue"
import TabPanel from "./components/TabPan.vue";


export default {
  name: "ProfileOverview",
  components: {
    JourneyInfoCard,
    HorizontalTimeline,
    TodoCard,
    RecomCard,
    TabPanel
},
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      activeTab: "Tab 1"
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    handleTabChange(tab) {
      this.activeTab = tab;
    }
  }
};
</script>
<style>
/* Define the CSS transitions for the slide animation */

.slide-enter-active{
  transition-delay: 0.5s;
  position: absolute;
  opacity: 0;
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter{
  opacity: 0;
}
/* Define the initial state of the elements entering */
 .slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Ensure proper layout of the tab content using flexbox */
.panelContainer-fluid {
  display: flex;
  justify-content: space-between;
  overflow-x: hidden; /* Hide overflow to prevent horizontal scroll */
}

.tabPanel-content {
  flex: 0 0 auto; /* Prevent flex items from growing */
  width: 100%; /* Ensure each tab content occupies full width */
}
</style>