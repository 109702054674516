<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-success"
            :dark-mode="true"
          />
        </div>
      </div>
    </div>
    <!-- Preloader  -->
    <main class="mt-0 main-content main-content-bg" v-if="!show">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div style="justify-content: center;">
                <Preloader />
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Activated Scenario  -->
    <main class="mt-0 main-content main-content-bg" v-if="activated && show">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder text-success text-gradient">
                      Thanks for activating your account!
                    </h3>
                    <p class="mb-0">Enter your email and password to sign in</p>
                  </div>
                  <div class="card-body">
                    <form role="form" class="text-start" @submit.prevent="signIn">
                      <label>Email</label>
                      <input type="email" id="email"
                        v-model="client.email"
                        class="form-control"
                        placeholder="Email"
                        required>
                      <label>Password</label>
                      <input type="password" id="password"
                        v-model="client.password"
                        class="form-control"
                        placeholder="Password"
                        style="margin-bottom: 2rem;"
                        required>
                      <soft-switch id="rememberMe" name="rememberMe" checked>
                        Remember me
                      </soft-switch>
                      <div class="text-center">
                        <soft-button
                          class="my-4 mb-2"
                          variant="gradient"
                          color="success"
                          full-width
                          >Sign in
                        </soft-button>
                      </div>
                      <div v-if="signInError">
                        <soft-alert color="danger">
                          <strong>Error!</strong> {{signInError}}
                        </soft-alert>
                      </div>
                      <div v-if="signInSuccess">
                        <soft-alert color="success">
                          <strong>Success!</strong> You will be redirected to your Dashboard shortly
                        </soft-alert>
                      </div>
                    </form>
                  </div>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-4 text-sm">
                      Don't have an account?
                      <router-link
                        :to="{ name: 'Sign Up' }"
                        class="text-success text-gradient font-weight-bold"
                        >Sign up</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
                >
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved9.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Activation Error Scenario  -->
    <main class="mt-0 main-content main-content-bg" v-if="!activated && show">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder">
                      Activation Failed
                    </h3>
                    <div>
                        <soft-alert color="danger">
                          <strong>Error!</strong> {{activationError}}
                        </soft-alert>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
                >
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved9.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <app-footer />
  </template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import AppFooter from "@/examples/PageLayout/Footer.vue";
  import SoftSwitch from "@/components/SoftSwitch.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import Preloader from "./components/Preloader.vue";
  const body = document.getElementsByTagName("body")[0];
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from 'vuex';
  
  export default {
    name: "Activation",
    data() {
      return {
        client: {
          email: "",
          password: ""
        },
        signInError: null,
        signInSuccess: false,
        activated: false,
        activationError: null,
        show: false,
      }
    },
    components: {
      Navbar,
      AppFooter,
      SoftSwitch,
      SoftButton,
      SoftAlert,
      Preloader
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");

        this.activate();

    },
    beforeUnmount() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
      body.classList.add("bg-gray-100");
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
      ...mapActions(['setAccessToken', 'setUser', 'setRefreshToken']),
      async activate() {
        let activationData={
            "uid": this.$route.params.uid,
            "token": this.$route.params.token
        };
        try {
          const response = await axios.post('/auth/users/activation/', activationData);
          if (response.status == 204) {
            this.activated = true;
          }
          
        } catch (error) {
          // Handle errors
          console.error('Error activating', error.response.data);
          this.activationError = error.response.data;
        }
        setTimeout(() => {
          this.show=true;
        }, 300);
      },
      async signIn() {
        try {
          // Make a POST request to your API endpoint
          const response = await axios.post('/api/auth/jwt/create', this.client);
            // Redirect to the login page after successful signup
            this.signInSuccess = true;
            const clientData = response.data;
            await this.setAccessToken(clientData.access);
            await this.setRefreshToken(clientData.refresh);
            await this.setUser(clientData);
            setTimeout(() => {this.$router.push({ name: 'Dashboard' });}, 1000);
        } catch (error) {
          // Handle errors
          console.error('Error signing in:', error.response.data);
          this.signInError = error.response.data;
        }
      }
    },
    computed: {
      ...mapGetters(['getAccessToken', 'getRefreshToken', 'getUser']),
    },
    
  };
  </script>
  