<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header card-hover">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">{{ journey.name }} Introduction</h6>
        </div>
        <div class="col-md-4 text-end">
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p class="text-sm">
        {{ journey.description }}
      </p>
      <hr class="my-4 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Clients' Need:</strong> &nbsp;
          {{ journey.disorder_data.name }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Main Approach:</strong> &nbsp; {{ journey.approach_data.name }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Number of Phases:</strong> &nbsp; {{ journey.phases.length }}
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Is this a template that people can clone?</strong> &nbsp; {{ journey.template ? 'Yes' : 'No' }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "JourneyInfoCard",
  components: {

  },
  props: {
    journey: {},
  },
};
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
}
</style>
