<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header card-hover">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">{{ phase.title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <router-link :to="{ name: 'Edit Journey', params: { id: this.phase.journey }}">
            <i
              class="text-sm fas fa-user-edit text-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
            ></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p class="text-sm">
        {{ phase.description }}
      </p>
      <hr class="my-4 horizontal gray-light" />
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Phase Number:</strong> &nbsp;
          {{ (index)+1 }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "PhaseInfoCard",
  components: {
    
  },
  props: {
    phase : [],
    index: Number,
  },
  methods: {

  }
};
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>
