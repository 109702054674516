<template>
    <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
      <div class="card h-100 border">
        <div class="card-header text-center"><i class="fa fa-file"></i><h5 class="card-title">{{ file.name }}</h5></div>
        
        <div class="card-body text-center" style="display: flex;align-content: center;flex-wrap: wrap; justify-content: center;">
          
          <!-- view file -->
          <a :href="file.file" class="btn btn-success m-2" target="_blank">View File</a>
          <!-- Delete file  -->
          <button class="btn btn-danger m-2" @click="showPopup = true">Delete</button>
        </div>
        <!-- Popup  -->
        <Popup v-if="showPopup"
          title="Are you sure?"
          description="This file is about to be removed. Are you sure you want to delete it?"
          color="danger"
          confirmButtonText="Delete"
          @close="showPopup = false"
          @confirm="deleteFile"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Popup from './Popup.vue';
  
  export default {
    name: 'FileItem',
    props: {
      file: Object
    },
    data() {
      return {
        showPopup: false
      };
    },
    components: {
      Popup
    },
    methods: {
      deleteFile() {
        this.$emit('delete-file', this.file.id);
        this.showPopup = false;
      }
    }
  };
  </script>
  