<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="container-fluid mt-4">
    <!-- Step 1 -->
    <div class="card col-lg-12 mb-4" id="step1" v-if="show">
      <!-- header of table  -->
      <div class="card-header pb-0">
        <h6 style="margin-bottom: 1rem; margin-left: 1rem;">Choose a client to create a session</h6>
        <div class="row" style="margin-left: 1rem;">
            <input
            id="search"
            type="text"
            class="form-control"
            style="width: auto; height: fit-content;"
            name="search"
            v-model="searchQuery"
            placeholder="search clients ..."
            
          />
          <div class="col-md-3">
              <select v-model="showPerPage" class="form-select form-select-border-radius-3">
                <option active selected value=10>10 Clients Per Page</option>
                <option value=50> 50 Clients Per Page </option>
              </select>
          </div>
          <div class="col-lg-3">
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><i class="fa fa-arrow-left" style="color: green;"></i></button>
                </li>
                <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                  <button class="page-link" @click="changePage(pageNumber)">{{ pageNumber }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="page-link" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><i class="fa fa-arrow-right" style="color: green;"></i></button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!-- table of clients  -->
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Client
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="client in paginatedClients" :key="client">
                <td class="text-center align-items-center">
                  <div style="display: inline-flex;">
                    <div class="form-check mt-1">
                      <input type="checkbox" class="form-check-input" :id="'clientCheckbox-' + client.id" :checked="client === selectedClient" @change="handleCheckboxChange(client)" :disabled="step != 1">
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <a :href="'/clients/'+ client.id">
                      <soft-avatar
                        :img="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed=' + client.first_name"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="{{ client.first_name + ' ' + client.last_name }}"
                      /></a>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <a :href="'/clients/'+ client.id"><h6 class="mb-0 text-sm">{{ client.first_name + ' ' + client.last_name }}</h6></a>
                      <p class="text-xs text-secondary mb-0">
                        {{client.email}}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Button zone  -->
      <div class="inline m-4" v-if="step == 1"> 
        <a
            class="btn bg-gradient-info m-1"
            href="/sessions"
            type="button"
            ><i class="fa fa-arrow-left"></i> Go to all sessions</a
          >
        <a
            v-if="selectedClient != null"
            class="btn bg-gradient-success m-1"
            href="#step2"
            type="button"
            @click="nextStep"
            >Save and continue <i class="fa fa-arrow-right"></i></a
          >
      </div>

    </div>

    <!-- alert zone  -->
    <div class="col-lg-4 mt-2" v-if="showMessage">
      <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
        {{message}}
      </soft-alert>
    </div>

    <!-- Step 2 -->
    <form role="form" class="text-start" id="step2" @submit.prevent="createSession" v-if="step == 2">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <label for="title" class="form-label">Choose a title for your session</label>
              <input type="text" id="title" v-model="session.title" class="form-control" required :disabled="step != 2">
            </div>          
            <div class="col-lg-6">
              <label for="datetimepicker">Select a Date:</label>
              <input type="datetime-local" id="datetimepicker" class="form-control" v-model="session.datetime">
            </div>
          </div>
          <div class="col-lg-6 inline mt-5" v-if="step == 1">
            <button
                class="btn bg-gradient-info m-1"
                @click="back"
                ><i class="fa fa-arrow-left"></i> Back
            </button>
            <button
                class="btn bg-gradient-success m-1"
                >Save & Continue <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>

        
        <div class="inline m-4" v-if="step == 2"> 
        <a
            class="btn bg-gradient-info m-1"
            href="/sessions"
            type="button"
            ><i class="fa fa-arrow-left"></i> Go to all sessions</a
          >
        <button
          class="btn bg-gradient-success m-1"
          >Create a new session!
        </button>
      </div>
      </div>
    </form>
    
  </div>
</template>
<script>

import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

import axios from 'axios';

export default {
  name: "clients-table",
  data() {
    return {
      step: 1,
      showMessage: false,
      message:'',
      messageType: '',
      show: false,
      searchQuery: "",
      showPerPage: 10, // Default number of clients to show per page
      currentPage: 1, // Current page number
      selectedClient: null,
      therapist: [],
      sessionCreationSuccess: false,
      session: {
      title: "",
      note: "default",
      datetime: "",
      }
    };
  },
  components: {
    SoftAvatar,
    SoftAlert,
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
        this.therapist = therapist[0];
      });
    this.show= true;
  },
  methods: {
    async fetchData(target) {
        let nextPage = target; // Replace this with your API endpoint
        let variable = [];

        try {
          while (nextPage) {
            const response = await axios.get(nextPage);
            const data = response.data;

            // Append results from current page to allInstances
            variable = variable.concat(data.results);

            // Update nextPage with the URL of the next page, if it exists
            nextPage = data.next;
          }

          // At this point, allInstances will contain all fetched instances
          return variable;
          
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },
    // Change the current page
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleCheckboxChange(client) {
      if (this.selectedClient === client) {
        this.selectedClient = null; // Unselect the client if it's already selected
      } else {
        this.selectedClient = null; // Select the clicked client
        this.selectedClient = client; // Select the clicked client
      }
    },
    nextStep(){
      this.step++
    },
    back(){
      this.step = this.step - 1
    },
    async createSession(){
        const sessionData = {
          "client": this.selectedClient.id,
          "title": this.session.title,
          "date_time": this.session.datetime,
          "therapist": this.therapist.id
        };
        try {
            //creating the session
            const response = await axios.post('/therapy/api/sessions/', sessionData);
            //Adding note to the session
            try {
              let payload={
                    "content": "",
                    "session": response.data.id
                };
              await axios.post('/therapy/api/notes/', payload);
              this.showMessage= true;
              this.message="Session created successfully."
              this.messageType= 'success'
              setTimeout(() => {
                this.showMessage= false;
              }, 2000);
              setTimeout(() => {this.$router.push({ name: 'Session View', params: { id: response.data.id } });}, 1000);
            } catch (error) {
              this.showMessage= true;
              this.message="Error: "+ error.response.data
              this.messageType= 'danger'
              setTimeout(() => {
                this.showMessage= false;
              }, 2000);
            }
            
            
        } catch (error) {
          this.showNoteMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showNoteMessage= false;
          }, 2000);
        }

      },
},
  computed: {
    filteredClients() {
      if (this.searchQuery=="") {
        return this.therapist.clients;
      } else {
        const query = this.searchQuery.toLowerCase();
        return this.therapist.clients.filter(client => {
          return (
            client.first_name.toLowerCase().includes(query) ||
            client.last_name.toLowerCase().includes(query) ||
            client.email.toLowerCase().includes(query)
            // You can add more properties for filtering if needed
          );
        });
      }
    },
    // Calculate total number of pages based on showPerPage and filtered clients
    totalPages() {
      return Math.ceil(this.filteredClients.length / this.showPerPage);
    },
    // Paginate the clients based on current page and showPerPage
    paginatedClients() {
      const startIndex = (this.currentPage - 1) * this.showPerPage;
      const endIndex = startIndex + this.showPerPage;
      return this.filteredClients.slice(startIndex, endIndex);
    },
  },
  watch: {
      // Watch for changes in showPerPage and reset currentPage to 1
      showPerPage: function(newShowPerPage, oldShowPerPage) {
        if (newShowPerPage !== oldShowPerPage) {
          this.currentPage = 1;
        }
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
};
</script>