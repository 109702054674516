<template>
  
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <div class="iframe-background">
      <iframe src="https://lottie.host/embed/57628c4e-c665-4c1f-9f73-3143fa5576fb/WAuKrOcQ2v.json"></iframe>
  </div>
 
  <main class="mt-0 main-content bg-index"> 
    
    <section>
      
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            
            <div class="mx-auto col-xl-6 col-lg-6 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain" style="background-color: transparent;">
                
                <div class="pb-0 card-header text-center bg-transparent">
                 
                  <img src="../assets/img/logo.png" alt="logo" class="mb-2" style="max-width: 100px;">
                  
                  <h2 class="font-weight-bolder" style="color: #00b661;">
                    Therapistory
                  </h2>
                  <h5 class="mb-0 text-white">A Modern Toolkit for Next Generation of Therapists</h5>
                </div>
                
                <div class="card-body mt-4">
                  <div class="row justify-center" style="justify-content: center;">
                    <div class="col-lg-5 text-center">
                      <a href="/sign-up" class="btn btn-success  w-100" style="background-color: #1fb560;">
                        Get Started for Free
                      </a>
                    </div>
                    <div class="col-lg-5">
                      <a href="/sign-in" class="btn btn-info bg-gradient-faded-dark w-100" style="background-color: #00b685;">
                        Sign In
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section>
    
  </main>

  <main class="main-content bg-index">
    <section>
      
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-6 col-md-6 d-flex flex-column" data-aos="fade-right">
              <div class="mt-4 card card-plain bg-gradient-dark">
                
                <div class="pb-0 card-header text-center bg-transparent">

                  <iframe height="250" src="https://lottie.host/embed/500746f2-e26a-4755-a32b-c1c638bc93fd/SoJIYzyAMp.json"></iframe>
   
                  
                  <h3 class="font-weight-bolder" style="color: #00b661;">
                    Secure & Efficient
                  </h3>
                  <h5 class="mb-4 text-white">Data Management System</h5>
                </div>
              </div>
            </div>
            <div class="mx-auto col-xl-4 col-lg-6 col-md-6 d-flex flex-column" data-aos="fade-up">
              <div class="mt-4 card card-plain bg-gradient-dark">
                
                <div class="pb-0 card-header text-center bg-transparent">

                  <iframe height="250" src="https://lottie.host/embed/cc617754-818b-4870-9b34-d449d4929c25/eKI8sihw03.json"></iframe>
   
                  
                  <h3 class="font-weight-bolder" style="color: #00b661;">
                    Journey-based Tech
                  </h3>
                  <h5 class="mb-4 text-white">For Therapy Journeys</h5>
                </div>
              </div>
            </div>
            <div class="mx-auto col-xl-4 col-lg-6 col-md-6 d-flex flex-column" data-aos="fade-left">
              <div class="mt-4 card card-plain bg-gradient-dark">
                
                <div class="pb-0 card-header text-center bg-transparent">

                  <iframe height="250" src="https://lottie.host/embed/728c63a0-a6a8-49de-90cc-f425c1d672b6/vnWYCAyj8m.json"></iframe>
   
                  
                  <h3 class="font-weight-bolder" style="color: #00b661;">
                    Smart
                  </h3>
                  <h5 class="mb-4 text-white">AI Assistant</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section>
    <app-footer />
  </main>
  
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from 'vuex';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

export default {
  name: "SignIn",
  data() {
    return {
      signInError: null,
      signInSuccess: false,
    }
  },
  components: {
    Navbar,
    AppFooter,
  },
  created() {
   
    this.toggleEveryDisplay();
    body.classList.remove("bg-gray-100");

    AOS.init({
      offset: 200,
      duration: 500,
      easing: 'ease-in-out',
      delay: 0,
    });
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay"]),
  },
  computed: {

  },
  
};
</script>
<style scoped>
.bg-index {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00081e;
  /* background-image: url('../assets/img/curved-images/curved14.jpg'); */
  background-size: cover;
  background-position: center;

}
.iframe-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  
}

.iframe-background iframe {
  width: 100%;
  height: 100%;
}

/* .card-plain {
  background-color: none !important; 
} */

</style>