<template>
  <nav class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs" id="navbarBlur"
    data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <!-- <div class="input-group">
            <span class="input-group-text text-body"
              ><i class="fas fa-search" aria-hidden="true"></i
            ></span>
            <input
              type="text"
              class="form-control"
              :placeholder="
                this.$store.state.isRTL ? 'أكتب هنا...' : 'Type here...'
              "
            />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end" v-if="show">

          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" type="button" @click="popup = true" :class="textWhite ? textWhite : 'text-body'">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li class="nav-item dropdown d-flex align-items-center" :class="this.$store.state.isRTL ? 'ps-2' : 'pe-2'">
            <a class="p-0 nav-link font-weight-bolder" :class="[
              textWhite ? textWhite : 'text-body',
              showMenu ? 'show' : '',
            ]" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              <i class="cursor-pointer fa fa-user-astronaut font-weight-bold"> Account</i> 
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <router-link :to="{name: 'Edit Profile', params: {id: therapist.id}}" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="https://api.dicebear.com/8.x/icons/svg?seed=Peanut" class="avatar avatar-sm me-3" alt="user image" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Account</span>
                        
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        Edit your info
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" type="button" @click.prevent="signOut">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img src="https://api.dicebear.com/8.x/icons/svg?seed=Kitty"
                        class="avatar avatar-sm bg-gradient-dark me-3" alt="logo spotify" />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Logout</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-sign-out me-1"></i>
                        Let's unplug you
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line" style="background: white;"></i>
                <i class="sidenav-toggler-line" style="background: white;"></i>
                <i class="sidenav-toggler-line" style="background: white;"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <Popup v-if="popup" title="This feature is coming soon!"
    description="Our dedicated team is working around the clock to deliver more features and tool kits for therapists. Each day we will deploy new features. Thank you for being with us in this journey."
    @close="popup = false" @confirm="popup = false"/>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import Popup from "../../views/components/Popup";
import axios from "axios";



export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      signOutError: null,
      popup: false,
      therapist: {},
      show: false,
    };
  },
  props: ["minNav", "textWhite"],
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
      this.show=true;
    })
    this.minNav;
    
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor", "clearTokens", "logout"]),
    signOut() {
      setTimeout(() => this.clearTokens().then(() => {
        this.$router.push({ name: 'Sign In' });
      }), 500);

      // // this.logout();
      // setTimeout(() => {this.$router.push({ name: 'Sign In' });}, 2000);

    },
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
  },
  components: {
    Breadcrumbs,
    Popup
  },
  computed: {
    ...mapGetters(['getAccessToken', "isAuthenticated", "getUser"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  updated() {
  },
};
</script>
