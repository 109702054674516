<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content bg-index">
    <section>
      <div class="iframe-background">
        <iframe src="https://lottie.host/embed/87c7d1f2-a76e-47dd-b6c5-b34589491d35/7xKHF4zt7Z.json"></iframe>
      </div>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain bg-white">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-black">
                    Set a new Email
                  </h3>
                  <p class="mb-0">Enter your new email below</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="resetEmail">
                    <label>Email</label>
                    <input type="email" id="email1"
                      v-model="payload.new_email"
                      class="form-control"
                      placeholder="Email"
                      required>
                    <label>Retype your email</label>
                    <input type="email" id="email2"
                      v-model="payload.re_new_email"
                      class="form-control"
                      placeholder="Retype Your Email"
                      required>

                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >Update Email
                      </soft-button>
                    </div>
                    <div class="card-body mt-2" v-if="showMessage">
                      <soft-alert :color="messageType">
                        {{message}}
                      </soft-alert>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Take me back to
                    <router-link
                      :to="{ name: 'Dashboard' }"
                      class="text-success text-gradient font-weight-bold"
                      >Dashboard</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer />
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue"
const body = document.getElementsByTagName("body")[0];
import axios from 'axios';
import { mapMutations, mapActions, mapGetters } from 'vuex';


export default {
  name: "SignIn",
  data() {
    return {
      signInError: null,
      signInSuccess: false,
      showForgetPassword: false,
      payload: {
        "uid": "",
        "token": "",
        "new_email": "",
        "re_new_email": ""
      },
      showMessage: false,
      message: '',
      messageType: '',
    }
  },
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    SoftAlert
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['setAccessToken', 'setUser', 'setRefreshToken']),
    async resetEmail(){
      this.payload.uid= this.$route.params.uid;
      this.payload.token= this.$route.params.token;
      try {
        await axios.post('/auth/users/reset_email_confirm/', this.payload);
        this.showMessage= true;
        this.message="Email reset successful."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
          this.$router.push({ name: 'Sign In' });
        }, 2000);

      } catch (error) {
        console.log(error);
        this.showMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      }

    },
  },
  computed: {
    ...mapGetters(['getAccessToken', 'getRefreshToken', 'getUser']),
  },
  
};
</script>
<style scoped>
.bg-index {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00081e;
  /* background-image: url('../assets/img/curved-images/curved14.jpg'); */
  background-size: cover;
  background-position: center;

}
.iframe-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;

  
}
.iframe-background iframe {
  width: 100%;
  height: 100%;
}
</style>