<template>
    <div class="spinner">
      <div class="spinner-inner"></div>
    </div>
  </template>
  
  <style scoped>
  .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .spinner-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: #4dff00;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
  </style>
  