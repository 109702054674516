<template>
    <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
      <div class="card h-100 border" style="align-items: center;">
        <!-- Session Image -->
        <img :src="'https://api.dicebear.com/8.x/rings/svg?seed='+session.title" class="card-img-top" alt="Session Image" style="width: 140px; margin-top: 1rem;">
        
        <div class="card-body text-center">
          <!-- Session Title -->
          <h6 class="card-title">{{ session.title }}</h6>
          <!-- Redirect to session -->
          <router-link :to="{ name: 'Session View', params: { id: session.id }}" class="btn btn-dark bg-gradient">View Session</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SessionCard',
    props: {
      session: Object
    }
  };
  </script>
  