import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
  state: {
    isAuthenticated: false,
    user: localStorage.getItem('user') || "null",
    refreshToken: localStorage.getItem('refreshToken') || null,
    accessToken: localStorage.getItem('accessToken') || null, // Initialize token as null
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
  },
  mutations: {
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
      return Promise.resolve();
    },
    setUser(state, user) {
      if (typeof user === 'string') {
        state.user = JSON.parse(user);
      } else {
        state.user =  JSON.stringify(user);
      }
      localStorage.setItem('user', state.user);
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem('accessToken', accessToken);
      return Promise.resolve();
    },
    clearTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;

      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    login({ commit }) {
      // Perform login logic, and if successful:
      commit("setAuthenticated", true);
    },
    logout({ commit }) {
      // Perform logout logic:
      commit("setAuthenticated", false);
    },
    setRefreshToken({ commit }, refreshToken) {
      commit('setRefreshToken', refreshToken);
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    // Actions to set and clear token
    setAccessToken({ commit }, accessToken) {
      commit('setAccessToken', accessToken);
    },
    clearTokens({ commit }) {
      commit('clearTokens');
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {
    getAccessToken: (state) => state.accessToken,
    isAuthenticated: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
    getRefreshToken: (state)=> state.refreshToken
  },
});
