<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <box3d />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Clients" :to="{ name: 'Clients' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Journey" :to="{ name: 'Journeys' }">
          <template #icon>
            <spaceship />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Sessions" :to="{ name: 'Sessions' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal dark" />
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need help?"
      textSecondary="Please contact us"
      route="mailto:info@nahan.uk"
      label="customer support"
      icon="ni ni-diamond"
    />
    <a
      class="btn bg-gradient-dark mt-4 w-100"
      @click="popup = true"
      type="button"
      >Upgrade to pro</a
    >
    <Popup v-if="popup"
    title="What does Pro version offer?"
    description="Unlimited Clients + AI Assistant + A Surprise :). Tune in, we will update this platform soon."
    @close="popup = false" @confirm="popup = false"
     />
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import Office from "../../components/Icon/Office.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import {mapGetters} from 'vuex';
import Popup from "../../views/components/Popup";


export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      user: [],
      show: false,
      popup: false
    };
  },
  components: {
    SidenavCollapse,
    SidenavCard,
    Office,
    Box3d,
    Document,
    Spaceship,
    Popup
},
computed: {
      ...mapGetters(['getToken', 'getRToken', 'getUser']),
      parsedUser() {
      return JSON.parse(this.getUser) || null;
    }
  },
methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
}
  
</script>
