<template>
  <div class="card h-100 w-95 border">
    <form @submit.prevent="updatePhase" class="g-3 text-start" role="form">
      <div class="p-3 card-body">
        <h6>Edit Phase Data</h6>
        <label class="text-info">Phase Number: {{ index + 1 }}</label>       
        <div>
          
          <label for="title" class="form-label">Title:</label>
          <input type="text" id="title" v-model="edited_phase.name" class="form-control" required>
        </div>
        <div>
          <label for="description" class="form-label">Description:</label>
          <textarea id="description" v-model="edited_phase.description" class="form-control" required></textarea>
        </div>
        <label for="todo" class="form-label mt-3">Add up to 5 important tasks, points or milestones for this phase:</label> 
        <div v-for="todo in edited_phase.todos" :key="todo">
          <input type="text" :id="todo.id" v-model="todo.description" class="form-control mt-1" required>
        </div>
        <div>

        </div>
        
        <div class="row d-flex">
          <div class="col-lg-6 mt-3 d-flex justify-content-center">
            <button type="button" @click.prevent="showPopup=true"  class="btn btn-danger bg-gradient-danger">Remove Phase</button>
          </div>
          <div class="col-lg-6 mt-3 d-flex justify-content-center">
            <button type="submit" class="btn btn-warning bg-gradient-warning">Update Phase</button>
          </div>
        </div> 
      </div>
    </form>
  </div>
  <Popup v-if="showPopup"
      title="Are you sure?"
      description="This phase is about to be removed. Are you sure you want to proceed?"
      color="danger"
      confirmButtonText="Delete"
      @close="showPopup = false"
      @confirm="removePhase"
    />
</template>
  
<script>
import Popup from './Popup.vue'
export default {
  name: "AssignmentForm",
  data() {
    return {
      edited_phase: this.phase,
      showPopup: false,
    };
  },
  props: {
    phase: Object,
    index: Number
  },
  components: {
    Popup
  },
  methods: {
    updatePhase() {
      // Push the new task to the tasks array or dispatch an action to a Vuex store
      this.$emit('update-phase', this.edited_phase, this.edited_phase.todos);
    },
    removePhase() {
      this.showPopup= false;
      // Push the new task to the tasks array or dispatch an action to a Vuex store
      this.$emit('remove-phase', this.edited_phase);
      
    },
  }
};
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>