<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>

  <!-- Main  -->
  <div class="container-fluid mt-4">
    <h5>Edit Your Client's Data</h5>
    <hr>
    <!-- STEP 1 -->
    <form role="form" class="text-start" @submit.prevent="nextStep">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's First Name</label>
                  <input type="text" id="title" v-model="client.first_name" placeholder="First Name" class="form-control" required :disabled="step != 1">
                </div>
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's Last Name</label>
                  <input type="text" id="title" v-model="client.last_name" placeholder="Last Name" class="form-control" required :disabled="step != 1">
                </div> 
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's Email</label>
                  <input type="email" id="title" v-model="client.email" placeholder="Email" class="form-control" required :disabled="step != 1">
                </div>           
            </div>
          
            <div class="row">
              <div class="col-lg-4">
                <label>What is the current status of your client?</label>
                  <select v-model="client.status" class="form-select form-select-border-radius-3" :disabled="step != 1">
                    <option disabled selected>Please select one</option>
                    <option v-for="item in statusOptions" :key="item">{{item}}</option>
                  </select>
              </div>
            </div>
            <div class="col-lg-6 inline mt-5" v-if="step == 1">
              <a
                  class="btn bg-gradient-info m-1"
                  type="button"
                  href="/clients"
                  ><i class="fa fa-arrow-left"></i> Back to Clients
              </a>
              <button
                  class="btn bg-gradient-success m-1"
                  >Save & Continue <i class="fa fa-arrow-right"></i></button
                >
            </div>
        </div>
      </div>
    </form>

    <!-- STEP 2 -->
    <form role="form" class="text-start" @submit.prevent="updateClientData">
      <div class="card h-100" style="margin-bottom: 1rem;" v-if="step != 1">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <label for="description" class="form-label">Introduce this client in less than 100 words:</label>
              <textarea type="text" id="description" v-model="client.introduction" class="form-control" :disabled="step != 2"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
                  <label for="title" class="form-label">Client's Job</label>
                  <input type="text" id="title" v-model="client.job" class="form-control" placeholder="Teacher" :disabled="step != 2">
          
            </div>
            <div class="col-lg-4">
                  <label for="title" class="form-label">Client's Phone Number</label>
                  <input type="text" v-model="client.phone_number"  class="form-control" @input="validatePhoneNumber" placeholder="Enter phone number, e.g., +1234567890" :disabled="step != 2">
                  <p v-if="!isValidPhoneNumber" class="text-xs text-danger mt-1">
                    Please enter a valid phone number in international format, e.g., +1234567890</p>
                  <p v-if="phoneNumberInfo" class="text-xs text-success mt-1">Country Code: {{ phoneNumberInfo.countryCode }}, Number: {{ phoneNumberInfo.nationalNumber }}</p>
          
            </div> 
            <div class="col-lg-4">
              <label for="title" class="form-label">Client's Address</label>
              <input type="text" class="form-control" v-model="client.location" @input="fetchCities" placeholder="Enter city name" :disabled="step != 2">
              <ul v-if="cities.length">
                <li v-for="city in cities" :key="city.place_id" @click="selectCity(city)">
                  {{ city.display_name }}
                </li>
              </ul>
            </div>
            <div>
              
            </div>          
          </div>
          <div class="col-lg-4 mt-2" v-if="showMessage">
            <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
              {{message}}
            </soft-alert>
          </div>

          <div class="col-lg-6 inline mt-5" v-if="step == 2">
            <button
                class="btn bg-gradient-info m-1"
                @click="stepBack"
                ><i class="fa fa-arrow-left"></i> Back</button>
            <button
                class="btn bg-gradient-success m-1"
                >Update Client's Data <i class="fa fa-arrow-right"></i></button
              >
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import parsePhoneNumberFromString from 'libphonenumber-js'
import setTooltip from "@/assets/js/tooltip.js";
import SoftAlert from "@/components/SoftAlert.vue"
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "ClientProfileEdit",
  data() {
    return {
      cities: [],
      isValidPhoneNumber: true,
      phoneNumberInfo: null,
      iconBackground: "bg-gradient-success",
      statusOptions: ['Started', 'Ongoing', 'Completed'],
      showMessage: false,
      message: '',
      messageType: '',
      backButtonText: "All Clients",
      client: [],
      step: 1,
    }
  },
  components: {
    SoftAlert,


  },
  created() {
    this.getClient();
  },
  methods: {
    ...mapActions(['setToken', 'login']),
    nextStep(){
        this.step++;
    },
    async getClient(){
      try {
        const response= await axios.get(`/therapy/api/clients/${this.$route.params.id}/`);
        this.client = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
          throw error;
      }
    },
    async updateClientData() {
      try {
        // Make a PATCH request to update the client data
        const response = await axios.put(`/therapy/api/clients/${this.client.id}/`, this.client);
        this.client = response.data;
        
        this.showMessage= true;
          this.message="Client's data updated successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showMessage= false;
          }, 2000);
          setTimeout(() => {this.$router.push({ name: 'Client Profile' , params: {id: this.client.id} });}, 2000);

        } catch (error) {
          this.showMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showMessage= false;
          }, 2000);
        }
    },
    stepBack(){
      this.step = this.step - 1
    },
    allClients(){
      setTimeout(() => {this.$router.push({ name: 'Clients' });}, 100);
    },
    validatePhoneNumber() {
      try {
        
        const phoneNumberUtil = parsePhoneNumberFromString(this.client.phone_number);
        if (phoneNumberUtil && phoneNumberUtil.isValid()) {
          this.isValidPhoneNumber = true;
          this.phoneNumberInfo = {
            countryCode: phoneNumberUtil.countryCallingCode,
            nationalNumber: phoneNumberUtil.nationalNumber
          };
        } else {
          this.isValidPhoneNumber = false;
          this.phoneNumberInfo = null;
        }
      } catch (error) {
        console.error('Error validating phone number:', error);
        this.isValidPhoneNumber = false;
        this.phoneNumberInfo = null;
      }
    },
    async fetchCities() {
      if (this.client.location.length > 2) { // Adjust the minimum query length as needed
        try {
          const response = await axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
              q: this.client.location,
              format: 'json',
              limit: 3 // Limit the number of results
            }
          });
          this.cities = response.data;
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        this.cities = [];
      }
    },
    selectCity(city) {
      this.client.location = city.display_name;
      this.cities = []; // Hide the dropdown list
    },
  },
  computed: {
    ...mapGetters(['getToken', 'getRToken']),
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
};
</script>
