<template>
  <!-- Header and banner  -->
  <div class="container-fluid" v-if="show">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed=' + user.firstName"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{user.firstName + ' ' + user.lastName}}</h5>
            <p class="mb-0 text-sm font-weight-bold">{{user.job}}</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
        </div>
      </div>
    </div>
  </div>

  <!-- Card Rows  -->
  <div class="py-4 container-fluid">
    <div class="mt-3 row">
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <profile-info-card
          :title="user.firstName+`'s Introduction`"
          :description="user.summary"
          :info="{
            fullName: user.firstName +' '+ user.lastName,
            mobile: user.phone,
            email: user.email,
            location: user.location,
          }"
          :action="{
            route: 'javascript:;',
            tooltip: 'Edit Profile',
          }"
        />
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <ClientJourneyCard
          :journey="journey"
          :user="user"
        />
      </div>

      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <place-holder-card
                  :title="{ text: 'New Journey', variant: 'h5' }"
                  url="/journey"
                />
      </div>
    </div>
    <!-- Session Rows  -->
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Sessions</h6>
            <p class="text-sm">Reports, Notes and Assignments</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
                <default-project-card
                v-for="session in sessions.filter(item=>item.user.id == this.user.id)" :key="session"
                :title="session.title"
                :image="'https://picsum.photos/seed/'+session.id+'/300/170'"
                :label="'Session '+ (sessions.filter(item=>item.user.id == this.user.id).findIndex(item=>item.id == session.id)+1)"
                description=""
                :action="{
                  route: '/session/'+session.id,
                  color: 'success',
                  label: 'View Session',
                }"
              />

              <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
                <place-holder-card
                  :title="{ text: 'New Session', variant: 'h5' }"
                  url="/session/new"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";

import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import DefaultProjectCard from "./components/DefaultProjectCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ClientJourneyCard from './components/ClientJourneyCard.vue';
import axios from 'axios';

export default {
  name: "ProfileOverview",
  components: {
    ProfileInfoCard,
    DefaultProjectCard,
    PlaceHolderCard,
    ClientJourneyCard
  },
  data() {
    return {
      user: [],
      journey:[],
      showMenu: false,
      sessions:[],
      show: false,

    };
  },
  created() {
    this.getUser();
    this.fetchAllInstances('clientSessions')
      .then(clientSessions => {
        this.sessions = clientSessions;
      })
      .catch(error => {
        console.error('Error fetching clientSessions:', error);
      });
    setTimeout(() => {
      this.show= true
    }, 200);
  },
  methods: {
    async fetchAllInstances(target) {
        let currentPage = 1;
        const pageSize = 10; // Adjust as per your API

        try {
          let allInstances = [];

          // Fetch the first page of instances
          let response = await this.fetchInstances(currentPage, pageSize, target);
          allInstances = allInstances.concat(response.data.data);

          // Continue fetching subsequent pages until there are no more pages left
          while (response.data.data.length === pageSize) {
            currentPage++;
            response = await this.fetchInstances(currentPage, pageSize, target);
            allInstances = allInstances.concat(response.data.data);
          }

          // At this point, allInstances contains data from all pages
          return allInstances;
        } catch (error) {
          console.error(`Error fetching ${target}:`, error);
        }
    },
    async fetchInstances(page, pageSize, target) {
        const params = {
          page,
          pageSize,
          // Add any other pagination parameters as needed
        };

        return await axios.get(`/api/v1/${target}`, { params });
    },
    async getClient(){
      try {
          const response = await axios.get(`/api/v1/clients/${this.$route.params.id}`);
          console.log('user fetched successfully:', response.data);
          this.user = response.data
          this.fetchAssignedJourney(this.user.generalJourneys[0].id)
        } catch (error) {
          console.error('Error fetching user:', error);
        }
    },
    async fetchAssignedJourney(id){
      try {
          const response = await axios.get(`/api/v1/generalJourneys/${id}`);
          console.log('Assigned journey fetched successfully:', response.data);
          this.journey = response.data
        } catch (error) {
          console.error('Error fetching assigned journey:', error);
        }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
