<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>

  <!-- Main  -->
  <div class="container-fluid mt-4">
    <h5>Add a new client</h5>
    <hr>
    <!-- STEP 1 -->
    <form role="form" class="text-start" @submit.prevent="nextStep">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's First Name</label>
                  <input type="text" id="title" v-model="client.first_name" placeholder="First Name" class="form-control" required :disabled="step != 1">
                </div>
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's Last Name</label>
                  <input type="text" id="title" v-model="client.last_name" placeholder="Last Name" class="form-control" required :disabled="step != 1">
                </div> 
                <div class="col-lg-4">
                  <label for="title" class="form-label">Enter Client's Email</label>
                  <input type="email" id="title" v-model="client.email" placeholder="Email" class="form-control" required :disabled="step != 1">
                </div>           
            </div>
          
            <div class="row">
              <div class="col-lg-4">
                <label>What is the current status of your client?</label>
                  <select v-model="client.status" class="form-select form-select-border-radius-3" :disabled="step != 1">
                    <option disabled selected>Please select one</option>
                    <option v-for="item in statusOptions" :key="item">{{item}}</option>
                  </select>
              </div>
            </div>
            <div class="col-lg-6 inline mt-5" v-if="step == 1">
              <a
                  class="btn bg-gradient-info m-1"
                  type="button"
                  href="/clients"
                  ><i class="fa fa-arrow-left"></i> Back to Clients
              </a>
              <button
                  class="btn bg-gradient-success m-1"
                  >Save & Continue <i class="fa fa-arrow-right"></i></button
                >
            </div>
        </div>
      </div>
    </form>

    <!-- STEP 2 -->
    <form role="form" class="text-start" @submit.prevent="createClient">
      <div class="card h-100" style="margin-bottom: 1rem;" v-if="step != 1">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <label for="description" class="form-label">Introduce this client in less than 100 words:</label>
              <textarea type="text" id="description" v-model="client.introduction" class="form-control" :disabled="step != 2"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
                  <label for="title" class="form-label">Client's Job</label>
                  <input type="text" id="title" v-model="client.job" class="form-control" placeholder="Teacher" :disabled="step != 2">
          
            </div>
            <div class="col-lg-4">
                  <label for="title" class="form-label">Client's Phone Number</label>
                  <input type="text" v-model="client.phone_number"  class="form-control" @input="validatePhoneNumber" placeholder="Enter phone number, e.g., +1234567890" :disabled="step != 2">
                  <p v-if="!isValidPhoneNumber" class="text-xs text-danger mt-1">
                    Please enter a valid phone number in international format, e.g., +1234567890</p>
                  <p v-if="phoneNumberInfo" class="text-xs text-danger mt-1">Country Code: {{ phoneNumberInfo.countryCode }}, Number: {{ phoneNumberInfo.nationalNumber }}</p>
          
            </div> 
            <div class="col-lg-4">
              <label for="title" class="form-label">Client's Address</label>
              <input type="text" class="form-control" v-model="client.location" @input="fetchCities" placeholder="Enter city name" :disabled="step != 2">
              <ul v-if="cities.length">
                <li v-for="city in cities" :key="city.place_id" @click="selectCity(city)">
                  {{ city.display_name }}
                </li>
              </ul>
            </div>
            <div>
              
            </div>          
          </div>
          <div v-if="addingNewClientSuccess" class="mt-3">
            <soft-alert color="success">
              <strong>Success!</strong> ِYour client has been added!
            </soft-alert>
          </div>
          <div v-if="addingNewClientFailed" class="mt-3">
            <soft-alert color="danger">
              <strong>Error!</strong> ِ{{addingNewClientFailed}}
            </soft-alert>
          </div>
          <div class="col-lg-6 inline mt-5" v-if="step == 2">
            <button
                class="btn bg-gradient-info m-1"
                @click="stepBack"
                ><i class="fa fa-arrow-left"></i> Back</button>
            <button
                class="btn bg-gradient-success m-1"
                >Save & Continue <i class="fa fa-arrow-right"></i></button
              >
          </div>
        </div>
      </div>
    </form>
    
    <!-- STEP 3 -->
    <div v-if="step == 3">
        <div class="text-center" v-if="showClientMessage">
          <div class="col-lg-4 mt-2" >
              <soft-alert :color="messageType">
                {{message}}
              </soft-alert>
          </div>
        </div>
        <div v-if="therapist.created_journeys.length > 0">
          <AllJourneys
          title="Assign one of your created journeys"
          :journeys="therapist.created_journeys.filter(item=>item.template==true)"
          @back="allClients" @assign-journey="assignJourney" :backButtonText="backButtonText"/>
        </div>
        <div v-if="assignSuccess" class="mt-3">
          <soft-alert color="success">
            <strong>Success!</strong> ِYour client has been assigned a journey!
          </soft-alert>
        </div>
        <div>
          <AllJourneys title="Assign a Journey Template to your client" :journeys="journeys" @back="allClients" @assign-journey="assignJourney" :backButtonText="backButtonText"/>
        </div>

    </div>

  </div>
</template>

<script>
import parsePhoneNumberFromString from 'libphonenumber-js'
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import AllJourneys from "./components/AllJourneys.vue";
import SoftAlert from "@/components/SoftAlert.vue"
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';


export default {
  name: "New Client",
  data() {
    return {
      showClientMessage: false,
      message: '',
      messageType: '',
      cities: [],
      assignSuccess: false,
      isValidPhoneNumber: true,
      phoneNumberInfo: null,
      iconBackground: "bg-gradient-success",
      statusOptions: ['Started', 'Ongoing', 'Completed'],
      addingNewClientSuccess: false,
      addingNewClientFailed: null,
      backButtonText: "All Clients",
      client: {
          "email": "",
          "first_name": "",
          "last_name": "",
          "introduction": "",
          "profile_pic": null,
          "phone_number": "",
          "location": "",
          "status": null,
          "therapist": null,
          "journey": null
      },
      newClient: null,
      step: 1,
      journeys: [],
      userJourneys: [],
      therapist: []
    }
  },
  components: {
    AllJourneys,
    SoftAlert

  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
    });
    await this.fetchData('/therapy/api/template_journeys/').then((journeys)=>{
      this.journeys = journeys;
    })

  },
  methods: {
    ...mapActions(['setToken', 'login']),
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    async createClient(){
      this.client.therapist = this.therapist.id
        try {
          // Make a POST request to your API endpoint
          const response = await axios.post('/therapy/api/clients/', this.client);
          if (response.status === 201) {
            this.addingNewClientSuccess = true;
            this.step++;
            this.client = response.data
            setTimeout(() => {this.addingNewClientSuccess = false;}, 3000);
            
          } else {
            // Handle signup errors
            console.error('Adding New Client Failed:', response.data.error);
            // Optionally, display an error message to the user
            this.addingNewClientFailed = response.data.error;
            setTimeout(() => {this.addingNewClientFailed = false;}, 5000);
          }
        } catch (error) {
          // Handle errors
          console.error('Error Adding New Client:', error.response.data);
          this.addingNewClientFailed = error.response.data;
          setTimeout(() => {this.addingNewClientFailed = false;}, 4000);
        };
    },
    async assignJourney(selectedJourney){
      this.client.journey= selectedJourney.id
      try {
        await axios.put(`/therapy/api/clients/${this.client.id}/`, this.client)
        this.showClientMessage= true;
        this.message="Journey is assigned successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showClientMessage= false;
          this.$router.push({ name: 'Client Profile', params: { id: this.client.id}});
        }, 2000);
      } catch (error) {
        this.showClientMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showClientMessage= false;
        }, 2000);
      }
    },
    stepBack(){
      this.step = this.step - 1
    },
    nextStep(){
        this.step++;
    },
    allClients(){
      setTimeout(() => {this.$router.push({ name: 'Clients' });}, 200);
    },
    validatePhoneNumber() {
      try {
        
        const phoneNumberUtil = parsePhoneNumberFromString(this.client.phone_number);
        if (phoneNumberUtil && phoneNumberUtil.isValid()) {
          this.isValidPhoneNumber = true;
          this.phoneNumberInfo = {
            countryCode: phoneNumberUtil.countryCallingCode,
            nationalNumber: phoneNumberUtil.nationalNumber
          };
        } else {
          this.isValidPhoneNumber = false;
          this.phoneNumberInfo = null;
        }
      } catch (error) {
        console.error('Error validating phone number:', error);
        this.isValidPhoneNumber = false;
        this.phoneNumberInfo = null;
      }
    },
    async fetchCities() {
      if (this.client.location.length > 2) { // Adjust the minimum query length as needed
        try {
          const response = await axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
              q: this.client.location,
              format: 'json',
              limit: 3 // Limit the number of results
            }
          });
          this.cities = response.data;
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        this.cities = [];
      }
    },
    selectCity(city) {
      this.client.location = city.display_name;
      this.cities = []; // Hide the dropdown list
    },
  },
  computed: {
    ...mapGetters(['getToken', 'getRToken','getUser']),
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
};
</script>
