<template>
  <!-- Header and banner  -->
  <div class="container-fluid" v-if="show">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed=' + client.first_name"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{client.first_name + ' ' + client.last_name}}</h5>
            <p class="mb-0 text-sm font-weight-bold">{{client.job}}</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
        </div>
      </div>
    </div>
  </div>

  <!-- Card Rows  -->
  <div class="py-4 container-fluid" v-if="show">
    <div class="text-center" v-if="showClientMessage">
      <div class="col-lg-4 mt-2" >
          <soft-alert :color="messageType">
            {{message}}
          </soft-alert>
      </div>
    </div>
    <!-- Intro Cards and Journey  -->
    <div class="mt-3 row">
      <!-- Profile Info Card  -->
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <profile-info-card
          :title="client.first_name+`'s Introduction`"
          :description="client.introduction"
          :info="{
            fullName: client.first_name +' '+ client.last_name,
            mobile: client.phone_number,
            email: client.email,
            location: client.location,
          }"
          :action="{
            route: '/clients/'+client.id+'/edit',
            tooltip: 'Edit Profile',
          }"
        />
      </div>

      <!-- Journey Card  -->
      <div v-if="client.journey" class="mt-4 col-12 col-xl-4 mt-xl-0">
        <a :href="'/journeys/'+ client.journey_data.id">
          <ClientJourneyCard
          :journey="client.journey_data"
          :client="client"
          />
        </a>
      </div>
      
      <!-- CTA Buttons  -->
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <div class="card h-100">
          <div class="card-body text-center" style="display: flex;align-content: center;flex-wrap: wrap; justify-content: center;">
            <a v-if="client.journey" type="button" class="btn bg-gradient-warning w-90" @click.prevent="showAssignPopup = true">Unassign Current Journey</a>
            <a v-else type="button" class="btn bg-gradient-success w-90" @click.prevent="$router.push({ name: 'Assign Journey' })">Assign a journey</a>
            <a type="button" class="btn bg-gradient-danger w-90" @click.prevent="showPopup = true">remove client</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup  -->
    <Popup v-if="showPopup"
      title="Are you sure?"
      description="This client is about to be removed. Are you sure you want to proceed?"
      color="danger"
      confirmButtonText="Delete"
      @close="showPopup = false"
      @confirm="deleteClient"
    />
    <Popup v-if="showAssignPopup"
      title="Are you sure?"
      description="This journey is about to be unassigned. Are you sure you want to proceed?"
      color="danger"
      confirmButtonText="Unassign"
      @close="showAssignPopup = false"
      @confirm="unassignJourney"
    />

    <!-- Session Rows  -->
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Sessions</h6>
            <p class="text-sm">Reports, Notes and Assignments</p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <SessionCard v-for="session in client.sessions" :key="session" :session="session"/>

              <div class="mb-4 col-12 col-xl-4 mt-xl-0" >
                <place-holder-card
                  :title="{ text: 'New Session', variant: 'h5' }"
                  url="/session/new"
                  color="success"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Files  -->
    <div class="col-lg-4 mt-2" v-if="showMessage">
      <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
        {{message}}
      </soft-alert>
    </div>
    <div class="mt-4 row">
      <div class="card">
        <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Uploaded Files</h6>
            <p class="text-sm">You can upload your files here</p>
          </div>
        <div class="card-body row">
          <FileItem v-for="file in client.client_files" :key="file.id" :file="file" @delete-file="deleteFile"/>
          <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
            <FileUploadCard
              :title="{ text: 'New File', variant: 'h5' }"
              url="javascript::"
              @file-selected="uploadFile"
            />
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";

import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ClientJourneyCard from './components/ClientJourneyCard.vue';
import axios from 'axios';
import FileUploadCard from './components/FileUploadCard.vue';
import SoftAlert from '@/components/SoftAlert.vue';
import FileItem from "./components/FileItem.vue";
import Popup from "./components/Popup.vue";
import SessionCard from "./components/SessionCard.vue"

export default {
  name: "ProfileOverview",
  components: {
    ProfileInfoCard,
    PlaceHolderCard,
    ClientJourneyCard,
    FileUploadCard,
    SoftAlert,
    FileItem,
    Popup,
    SessionCard
  },
  data() {
    return {
      showMessage: false,
      showClientMessage: false,
      message: '',
      messageType: '',
      client: [],
      generalJourney:[],
      customJourney: [],
      showMenu: false,
      sessions:[],
      journeyType: '',
      show: false,
      showPopup: false,
      showAssignPopup: false,

    };
  },
  async created() {
    await this.getClient();
    setTimeout(() => {
      this.show= true
    }, 200);
  },
  methods: {
    async getClient(){
      try {
        const response= await axios.get(`/therapy/api/clients/${this.$route.params.id}/`);
        this.client = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
          throw error;
      }
    },
    async deleteClient(){
      this.showPopup= false;
      try {
        // Make a DELETE request to remove the file from the generalDocs API
        await axios.delete(`/therapy/api/clients/${this.client.id}/`);
        this.showClientMessage= true;
        this.message="Client is removed successfully."
        this.messageType= 'warning'
        setTimeout(() => {
          this.showClientMessage= false;
          this.$router.push({ name: 'Clients'});
        }, 2000);
      } catch (error) {
        this.showClientMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showClientMessage= false;
        }, 2000);
      }
    },
    async deleteFile(fileId) {
      try {
        // Make a DELETE request to remove the file from the generalDocs API
        await axios.delete(`/therapy/api/client_files/${fileId}/`);
        
        await this.getClient();
        this.showMessage= true;
        this.message="File deleted successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      } catch (error) {
        this.showMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      }
    },
    async uploadFile(file, fileName) {
      const maxFileSize = 10 * 1024 * 1024; //10m
      if (file.size <= maxFileSize) {
        try {
          // Create a FormData object to send the file
          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', fileName ? fileName : file.name);
          formData.append('therapist', this.client.therapist);
          formData.append('client', this.client.id);
          // formData.append('session', null);

          // Make a POST request to upload the file
          await axios.post('/therapy/api/client_files/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await this.getClient();
          this.showMessage= true;
          this.message="File uploaded successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showMessage= false;
          }, 2000);
        } catch (error) {
          // Handle error
          console.log(error);
          this.showMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showMessage= false;
          }, 3000);
        }
      } else {
        // Handle error
        this.showMessage= true;
        this.message="Error: "+ "file size bigger than 10m limit"
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 3000);
      }

    },
    async unassignJourney(){
      this.client.journey= null;
      this.showAssignPopup= false;
      try {
        const response = await axios.put(`/therapy/api/clients/${this.client.id}/`, this.client)
        console.log('Journey assigned successfully', response.data);

        this.showClientMessage= true;
        this.message="Journey is unassigned successfully."
        this.messageType= 'warning'
        setTimeout(() => {
          this.showClientMessage= false;
          this.getClient();
        }, 2000);
      } catch (error) {
        this.showClientMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showClientMessage= false;
        }, 2000);
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/therapy/api/clients/${to.params.id}/`)
      .then(response => {
        // Check if the response status indicates permission-denied
        if (response.status === 403 || response.status === 404) {
          // Redirect to 404 page
          next({ name: 'NotFound' });
        } else {
          // Proceed to enter the route
          next();
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Redirect to 404 page
        next({ name: 'NotFound' });
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
