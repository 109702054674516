<template>
    
        <timeline-list
          class="h-100"
          :title="'Overview of '+journey.name"
        >
        
            <div v-for="item in journey.phases" :key="item" class="mb-3 timeline-block" :class="darkMode">
                <span class="timeline-step" :class="darkMode ? 'bg-dark' : ''">
                <i class="ni text-gradient" :class=" item.status === 'complete' ? 'ni-check-bold text-success' : 'ni-key-25 text-danger' "></i>
                </span>
                <div class="timeline-content">
                <h6 class="mb-0 text-sm font-weight-bold" :class="darkMode ? 'text-white' : 'text-dark'"
                >{{ item.name }} </h6>
                <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">phase #{{ (item.number)+1 }}</p>
                </div>
            </div>
        </timeline-list>
</template>

<script>
import TimelineList from './TimelineList.vue';

export default {
    name: "JourneyCard",
    props: {
        journey:{},
    },
    data() {
        return {
            darkMode: false,

        }
    },
    components: {
        TimelineList,
    },
    created() {
  },
    
}
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>