<template>
  <div class="card h-100" style="background-color: #e0f5b8;">
    <form @submit.prevent="addPhase" class="g-3 text-start" role="form">
      <div class="p-3 card-body">
        <h6>Add New Phase</h6>        
        <div>
          <label for="title" class="form-label">Title:</label>
          <input type="text" id="title" v-model="newPhase.name" class="form-control" required>
        </div>
        <div>
          <label for="description" class="form-label">Description:</label>
          <textarea id="description" v-model="newPhase.description" class="form-control" required></textarea>
        </div>
        <label for="todo" class="form-label mt-3">Add up to 5 important tasks, points or milestones for this phase:</label>  
        <input type="text" id="1" v-model="newPhase.todos[0]" class="mt-1 form-control" required>
        <input type="text" id="2" v-model="newPhase.todos[1]" class="mt-1 form-control" required>
        <input type="text" id="3" v-model="newPhase.todos[2]" class="mt-1 form-control" required>
        <input type="text" id="4" v-model="newPhase.todos[3]" class="mt-1 form-control" required>
        <input type="text" id="5" v-model="newPhase.todos[4]" class="mt-1 form-control" required>    
      </div>
      <div class="col-12 mt-3 d-flex justify-content-center">
        <button type="submit" class="btn btn-success">Create Phase</button>
      </div> 
    </form>
  </div>
</template>
  
<script>
export default {
  name: "AssignmentForm",
  data() {
    return {
      newPhase: {
        name: '',
        description: '',
        todos: ['']
      }
    };
  },
  props: {
  },
  methods: {
    addPhase() {
      // Push the new task to the tasks array or dispatch an action to a Vuex store
      this.$emit('new-phase', this.newPhase, this.newPhase.todos);

      // Clear the form fields after submitting
      this.newPhase.name = '';
      this.newPhase.description = '';
      this.newPhase.todos = [];
    }
  }
};
</script>
<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>