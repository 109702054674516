<template>
  <div>
    <div class="dropdown d-inline-block">
      <button class="btn btn-primary dropdown-toggle rounded-pill" type="button" id="dropdownMenuButton"
        aria-expanded="false" @click="showMenu = !showMenu">
        <i class="fa"></i> {{ht == false ? selectedOption.number + 1 +'. ' + selectedOption.name : 'Overview'}}
      </button>
      <ul class="dropdown-menu dropdown-menu-center" :class="{ show: showMenu }" aria-labelledby="dropdownMenuButton">
        <li>
          <a class="dropdown-item" href="javascript:;" @click="showHT()">
            Overview
          </a>
        </li>
        <li v-for="(item, index) in phases" :key="item">
          <a class="dropdown-item" href="javascript:;" @click="selectOption(item)">
            {{ (index + 1) }}. {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
          <!-- Overview Tab  -->
      
      <div v-if="ht" class="card h-100">
        <HorizontalTimeline :journey="journey" :phases="phases"/>
      </div>

    <!-- showing content based on the chosen option  -->
      <div v-for="(filteredOption, index) in filteredOptions" :key="index" class="mt-3">
        <!-- Phase Tabs  -->
          <div class="mt-3 row">
            <!-- Info Card-->
            <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
              <PhaseInfoCardView :phase="filteredOption" :index="filteredOption.number" />
            </div>

            <!-- ToDo Card -->
            <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
              <TodoCard :todos="filteredOption.todos" @status-update="updateTodoStatus"/>
            </div>

            <!-- Journeys Card -->
            <div class="col-lg-4 col-md-6">
              <place-holder-card :title="{ text: 'Check out other journeys', variant: 'h6' }" url="/journey"
                color="success" />
            </div>
          </div>
          <div class="col-lg-4 mt-2" v-if="showPhaseMessage">
            <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
              {{message}}
            </soft-alert>
          </div>
          <!-- sections  -->
          <div v-for="section in sectionTypes" :key="section" class="mt-3 row">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-1">{{ section }}</h6>
                <p class="text-sm"></p>
              </div>
              <div class="card-body row">
                <div class="col-lg-4 col-md-6 mb-4">
                  <place-holder-card :title="{ text: section, variant: 'h5' }" url="javascript::" color="primary"
                    @click="popup = true" />
                </div>
              </div>
            </div>
          </div>
        
      </div>

      <!-- popup  -->
      <Popup v-if="popup"
      title="This feature is coming soon!"
      description="Our dedicated team is working around the clock to deliver more features and tool kits for therapists. Each day we will deploy new features. Thank you for being with us in this journey."
      @close="popup = false" @confirm="popup = false"
      />
  </div>
</template>

<script>
import HorizontalTimeline from "./HorizontalTimeline.vue";
import PhaseInfoCardView from "./PhaseInfoCardView.vue";
import TodoCard from "./TodoCard.vue";
import Popup from "./Popup.vue";
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import axios from 'axios'
import SoftAlert from '@/components/SoftAlert.vue';

export default {
  name: "TabPanel",
  data() {
    return {
      showMenu: false,
      sectionTypes: ['Books', 'Articles', 'Videos'],
      popup: false,
      selectedOption: null,
      ht: true,
      sortedPhases: [],
      showPhaseMessage: false,
      message: '',
      messageType: '',
    };
  },
  created() {
    // Create a copy of the phases prop
    this.sortedPhases = [...this.phases];
    // Sort the copied array based on the 'number' property
    this.sortedPhases.sort((a, b) => a.number - b.number);
  },
  props: {
    phases: Object,
    journey: Object
  },
  components: {
    HorizontalTimeline,
    PhaseInfoCardView,
    TodoCard,
    Popup,
    PlaceHolderCard,
    SoftAlert
  },
  methods: {
    async updateTodoStatus(id, value){
      let payload={
        "completed": value,
      };
      try {
          await axios.patch(`/therapy/api/todos/${id}/`, payload);
          this.showPhaseMessage= true;
          this.message="Progress updated successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showPhaseMessage= false;
          }, 2000);
      } catch (error) {
          this.showPhaseMessage= true;
          this.message="Error: "+ Object.values(error.response.data)
          this.messageType= 'danger'
          setTimeout(() => {
            this.showPhaseMessage= false;
          }, 2000);
      }
    },
    showHT(){
      this.ht= true;
      this.selectedOption= null,
      this.closeDropdownMenu();
    },
    selectOption(option) {
      this.selectedOption = option;
      this.ht=false;
      this.closeDropdownMenu();
    },
    closeDropdownMenu() {
      const dropdownToggle = document.querySelector(".dropdown-toggle");
      dropdownToggle.click(); // Programmatically click to close the dropdown menu
    }
  },
  computed: {
    filteredOptions() {
      if (!this.selectedOption) {
        return [];
      } else {
        return [this.selectedOption];
      }
    }
  },
};
</script>

<style scoped>
</style>
