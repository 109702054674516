<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>All Sessions</h6>
      <div class="row">
          <input
          id="search"
          type="text"
          class="form-control"
          style="width: auto; height: fit-content;"
          :class="getClasses(size, success, error)"
          name="search"
          v-model="searchQuery"
          placeholder="search sessions ..."
          
        />
        <div class="col-md-3">
            <select v-model="showPerPage" class="form-select form-select-border-radius-3">
              <option active selected value=10>10 Clients Per Page</option>
              <option value=50> 50 Clients Per Page </option>
            </select>
        </div>
        <div class="col-lg-3">
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><i class="fa fa-arrow-left" style="color: green;"></i></button>
              </li>
              <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                <button class="page-link" @click="changePage(pageNumber)">{{ pageNumber }}</button>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><i class="fa fa-arrow-right" style="color: green;"></i></button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Client
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                session details
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="session in paginatedSessions" :key="session">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <a :href="'/clients/'+ session.client.id">
                    <soft-avatar
                      :img="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed=' + session.client.first_name"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="{{ session.client.first_name + ' ' + session.client.last_name }}"
                    /></a>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <a :href="'/clients/'+ session.client.id"><h6 class="mb-0 text-sm">{{ session.client.first_name + ' ' + session.client.last_name }}</h6></a>
                    <p class="text-xs text-secondary mb-0">
                      {{session.client.email}}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ session.date_time ? session.date_time.substring(0, 10) : 'N/A'}}</p>
                <p class="text-xs text-secondary mb-0">total session: {{ sessions.filter(item=>item.client.id == session.client.id).length }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <soft-badge color="secondary" variant="gradient" size="sm"
                  ><a :href="'/session/'+ session.id" style="color: white;">view</a></soft-badge
                >
              </td>
              <td class="align-middle">
                <i class="fa fa-user text-success" aria-hidden="true"></i>
                  <a
                    :href="'/clients/'+ session.client.id"
                    class="text-secondary font-weight-bold text-xs"
                    > Client Zone
                  </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
// import SoftButton from "@/components/SoftButton.vue"

export default {
  name: "clients-table",
  data() {
    return {
      searchQuery: "",
      showPerPage: 10, // Default number of clients to show per page
      currentPage: 1, // Current page number
    };
  },
  props: {
    sessions: {},
  },
  components: {
    SoftAvatar,
    SoftBadge,
    // SoftButton
  },
  methods: {
    // Change the current page
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
  },
  computed: {
    filteredSessions() {
      if (this.searchQuery=="") {
        return this.sessions;
      } else {
        const query = this.searchQuery.toLowerCase();
        return this.sessions.filter(session => {
          return (
            session.client.first_name.toLowerCase().includes(query) ||
            session.client.last_name.toLowerCase().includes(query) ||
            session.client.email.toLowerCase().includes(query)
            // You can add more properties for filtering if needed
          );
        });
      }
    },
    // Calculate total number of pages based on showPerPage and filtered sessions
    totalPages() {
      return Math.ceil(this.filteredSessions.length / this.showPerPage);
    },
    // Paginate the sessions based on current page and showPerPage
    paginatedSessions() {
      const startIndex = (this.currentPage - 1) * this.showPerPage;
      const endIndex = startIndex + this.showPerPage;
      return this.filteredSessions.slice(startIndex, endIndex);
    },
  },
  watch: {
      // Watch for changes in showPerPage and reset currentPage to 1
      showPerPage: function(newShowPerPage, oldShowPerPage) {
        if (newShowPerPage !== oldShowPerPage) {
          this.currentPage = 1;
        }
    }
  },
};
</script>
