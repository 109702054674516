<template>
  <!-- header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <!-- Nav bar info  -->
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
        </div>
        <div class="col-auto my-auto" v-if="show">
          <div class="h-100">
            <h5 class="mb-1">{{journey.name}}</h5>
            <p class="mb-0 text-sm font-weight-bold">Designed based on <span class="text-success">{{journey.approach_data.name}}</span>  approach for <span class="text-info">{{ journey.disorder_data.name }}</span> cases</p>
          </div>
        </div>
        <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
          <div class="nav-wrapper position-relative">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#phase1"
                  href="#phase1"
                  role="tab"
                  id="phase1-tab"
                  aria-controls="phase1"
                  aria-selected="true"
                >
                  <span class="ms-1">Overview</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#phase2"
                  href="#phase2"
                  role="tab"
                  id="phase2-tab"
                  aria-controls="phase2"
                  aria-selected="true"
                >
                  <span class="ms-1">All Phases</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Main content  -->
  <div class="py-4 container-fluid tab-content" v-if="show">
    <div class="text-center" v-if="showClientMessage">
      <div class="col-lg-4 mt-2" >
          <soft-alert :color="messageType">
            {{message}}
          </soft-alert>
      </div>
    </div>
    <!-- Tab 1 -->
    <div class="tab-pane fade show active" id="phase1" role="tabpanel" aria-labelledby="phase1-tab">

      <div class="mt-3 row">

          <!-- Journey Summary -->
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <JourneyInfoCard 
          :journey="journey"
          />
        </div>

        <!-- Visualized Journey -->
        <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
          <JourneyCard
          :phases="journey.phases"
          :journey="journey"
          />
        </div>

        <!-- New Journey Placeholder -->
        <!-- CTA Buttons  -->
        <div class="mt-4 col-12 col-xl-4 mt-xl-0">
          <div class="card h-100">
            <div class="card-body text-center" style="display: flex;align-content: center;flex-wrap: wrap; justify-content: center;">
              <a type="button" class="btn bg-gradient-warning w-90" @click.prevent="$router.push({ name: 'Journeys'})">Go to Journey Pages</a>
              <a type="button" class="btn bg-gradient-success w-90" @click.prevent="$router.push({ name: 'Edit Journey', params: { id: journey.id} })">Edit Journey</a>
              <a type="button" class="btn bg-gradient-danger w-90" @click.prevent="showPopup = true">Remove the Journey</a>
            </div>
          </div>
        </div>
      </div>
          <!-- Popup  -->
    <Popup v-if="showPopup"
      title="Are you sure?"
      description="This journey is about to be removed. Are you sure you want to proceed?"
      color="danger"
      confirmButtonText="Delete"
      @close="showPopup = false"
      @confirm="deleteJourney"
    />
    </div>

    <!-- Tab 2 -->
    <div class="tab-pane fade show" id="phase2" role="tabpanel" aria-labelledby="phase2-tab">

        <TabPanel
        :phases="journey.phases" :journey="journey"/>
    </div>
    <!-- popup  -->
    <Popup v-if="popup"
    title="This feature is coming soon!"
    description="Our dedicated team are working around the clock to deliver more features and tool kits for therapists. Each day we will deploy new features. Thank you for being with us in this journey."
    @close="popup = false"
     />
  </div>
</template>

<script>
// import SoftSwitch from "@/components/SoftSwitch.vue";
import JourneyInfoCard from "./components/JourneyInfoCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import JourneyCard from "./components/JourneyCard.vue";
import TabPanel from "./components/TabPan.vue"
import SoftAlert from '@/components/SoftAlert.vue';
import axios from 'axios';
import Popup from './components/Popup.vue'


export default {
  name: "JourneyView",
  components: {
    JourneyInfoCard,
    JourneyCard,
    TabPanel,
    Popup,
    SoftAlert
  },
  data() {
    return {
      showClientMessage: false,
      message: '',
      messageType: '',
      showMenu: false,
      phaseIndex: 0,
      journey: [],
      sectionTypes: [],
      activeTab: "Overview",
      show: false,
      popup:false,
      showPopup: false,
      therapist: {}
    };
  },
  async created() {
    await this.getJourney();
    this.show = true;
  },
  methods: {
    setTypes(){
      this.sectionTypes = this.journey.phases.sections ? [...new Set(this.journey.phases.sections.map(item => item.type))] : ['Books', 'Articles', 'Videos'] 
    },
    async getJourney(){
      try {
        const response= await axios.get(`/therapy/api/journeys/${this.$route.params.id}/`);
        this.journey = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
          throw error;
      }

    },
    async deleteJourney(){
      this.showPopup= false;
      try {
        // Make a DELETE request to remove the file from the generalDocs API
        await axios.delete(`/therapy/api/journeys/${this.journey.id}/`);
        this.showClientMessage= true;
        this.message="Journey is removed successfully."
        this.messageType= 'warning'
        setTimeout(() => {
          this.showClientMessage= false;
          this.$router.push({ name: 'Journeys'});
        }, 2000);
      } catch (error) {
        this.showClientMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showClientMessage= false;
        }, 2000);
      }
    },
    handleTabChange(tab, index) {
      this.activeTab = tab;
      this.phaseIndex = ((index)-1);
      console.log(tab, index);
    },
    setIndex(index){
      this.phaseIndex = (index)-1;
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/therapy/api/journeys/${to.params.id}/`)
      .then(response => {
        // Check if the response status indicates permission-denied
        if (response.status === 403 || response.status === 404) {
          // Redirect to 404 page
          next({ name: 'NotFound' });
        } else {
          // Proceed to enter the route
          next();
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Redirect to 404 page
        next({ name: 'NotFound' });
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
<style>
/* Define the CSS transitions for the slide animation */

.slide-enter-active{
  transition-delay: 0.5s;
  position: absolute;
  opacity: 0;
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter{
  opacity: 0;
}
/* Define the initial state of the elements entering */
 .slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Ensure proper layout of the tab content using flexbox */
.panelContainer-fluid {
  display: flex;
  justify-content: space-between;
  overflow-x: hidden; /* Hide overflow to prevent horizontal scroll */
}

.tabPanel-content {
  flex: 0 0 auto; /* Prevent flex items from growing */
  width: 100%; /* Ensure each tab content occupies full width */
}
</style>