<template>
  <div class="card h-100 border"  :class="checked ? 'bg-gradient-success text-white': 'bg-gradient'">
    <div class="p-3 pb-0">
      <div class="row">
        <div class="col-lg-8 d-flex align-items-center" style="margin: auto;">
          <label class="mb-0 text-sm-start">{{ assignment.title }}</label>
        </div>
        <div class="col-lg-2 form-check">
          <input type="checkbox" class="form-check-input" :id="assignment.id"  v-model="checked" @change="updateCompletedStatus">
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <p class="text-sm">
        {{ assignment.description }}
      </p>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-sm btn-danger" @click="popup= true">Remove</button>
    </div>
  </div>
      <!-- Popup  -->
  <Popup v-if="popup"
  title="Are you sure?"
  description="All the data related to this assignment is about to be removed. Are you sure you want to delete the assignment?"
  color="danger"
  confirmButtonText="Delete"
  @close="popup = false"
  @confirm="removeAssignment"
    />
</template>

<script>
import Popup from './Popup.vue';

export default {
  name: "AssignmentInfoCard",
  data() {
    return {
      checked: this.assignment.status,
      popup: false,
    }
  },
  components: {
    Popup
  },
  props: {
    assignment :{},
  },
  emits: ['remove-assignment', 'status'], // Declare the event listeners
  methods: {
    removeAssignment() {
      this.$emit('remove-assignment', this.assignment.id);
    },
    updateCompletedStatus() {
      // You can perform additional logic here if needed
      this.$emit('status', this.assignment.id ,this.checked);
    }
  },
};
</script>

<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}</style>
