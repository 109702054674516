<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>

  <!-- Main  -->
  <div class="container-fluid mt-4">
    <h5>Edit Your Data</h5>
    <hr>
    <!-- STEP 1 -->
    <form role="form" class="text-start" @submit.prevent="updateUserData" v-if="show">
      <div class="card h-100" style="margin-bottom: 1rem;">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                  <label for="first_name" class="form-label">First Name</label>
                  <input type="text" id="first_name" v-model="therapist.first_name" placeholder="First Name" class="form-control" required>
                </div>
                <div class="col-lg-4">
                  <label for="last_name" class="form-label">Last Name</label>
                  <input type="text" id="last_name" v-model="therapist.last_name" placeholder="Last Name" class="form-control" required>
                </div> 
                <div class="col-lg-4">
                  <label for="email" class="form-label">Enter user's Email</label>
                  <input type="email" id="email" v-model="therapist.user.email" placeholder="Email" class="form-control" required disabled>
                </div>           
            </div>
            <div class="row">
              <div class="col-lg-6">
                  <label for="phone_number" class="form-label">Phone Number</label>
                  <input type="text" v-model="therapist.phone_number"  class="form-control" @input="validatePhoneNumber" placeholder="Enter phone number, e.g., +1234567890">
                  <p v-if="!isValidPhoneNumber" class="text-xs text-danger mt-1">
                    Please enter a valid phone number in international format, e.g., +1234567890</p>
                  <p v-if="phoneNumberInfo" class="text-xs text-success mt-1">Country Code: {{ phoneNumberInfo.countryCode }}, Number: {{ phoneNumberInfo.nationalNumber }}</p>
              </div> 
              <div class="col-lg-6">
                <label for="address" class="form-label">Address</label>
                <input type="text" class="form-control" v-model="therapist.address" @input="fetchCities" placeholder="Enter city name">
                <ul v-if="cities.length">
                  <li v-for="city in cities" :key="city.place_id" @click="selectCity(city)">
                    {{ city.display_name }}
                  </li>
                </ul>
              </div> 
            </div>
            <div class="row">
              <div class="col-lg-12">
                <label for="description" class="form-label">Introduce yourself in less than 100 words:</label>
                <textarea type="text" id="description" v-model="therapist.introduction" class="form-control"></textarea>
              </div>
            </div>
            <div class="col-lg-4 mt-2" v-if="showMessage">
              <soft-alert :color="messageType">
                {{message}}
              </soft-alert>
            </div>
            <div class="col-lg-12 inline mt-5" v-if="!showPassword">
              <router-link class="btn bg-gradient-info m-1" :to="{name: 'Dashboard'}">
                <i class="fa fa-arrow-left"></i> Dashboard
              </router-link>
              <button class="btn bg-gradient-success m-1">
                Update my Data <i class="fa fa-save"></i>
              </button>
              <button type="button" @click.prevent="emailChangeRequest" class="btn bg-gradient-warning m-1">
                Change Email <i class="fa fa-mail-bulk"></i>
              </button>
              <button type="button" @click.prevent="showPassword = true" class="btn bg-gradient-danger m-1">
                Delete My Account <i class="fa fa-trash"></i>
              </button>
            </div>
            <div class="row" v-if="showPassword">
              <div class="col-lg-6">
                <label for="password" class="form-label">Enter you current password to continue:</label>
                <input type="password" id="password" v-model="current_password" class="form-control">
              </div>
              <div class="col-lg-6 mt-4">
                <button type="button" @click.prevent="showPopup = true" class="btn bg-gradient-danger m-1">
                  Delete My Account <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
        </div>
      </div>
    </form>
      <!-- Popup  -->
      <Popup v-if="showPopup"
      title="Are you sure?"
      description="Your account and all the data related to it will be deleted. Are you sure you want to proceed?"
      color="danger"
      confirmButtonText="Delete"
      @close="showPopup = false"
      @confirm="deleteAccount"
    />
  </div>
</template>

<script>
// import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import parsePhoneNumberFromString from 'libphonenumber-js'
import setTooltip from "@/assets/js/tooltip.js";
import SoftAlert from "@/components/SoftAlert.vue"
import axios from 'axios';
import Popup from './components/Popup.vue'
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "ClientProfileEdit",
  data() {
    return {
      cities: [],
      isValidPhoneNumber: true,
      phoneNumberInfo: null,
      iconBackground: "bg-gradient-success",
      statusOptions: ['Started', 'Ongoing', 'Completed'],
      showMessage: false,
      message: '',
      messageType: '',
      backButtonText: "All Clients",
      therapist: {},
      step: 1,
      show: false,
      showPopup: false,
      current_password:'',
      showPassword: false,
    }
  },
  components: {
    SoftAlert,
    Popup
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
      this.show=true;
    });
    
  },
  methods: {
    ...mapActions(['setToken', 'login', 'clearTokens']),
    nextStep(){
        this.step++;
    },
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    async updateUserData() {
      try {
        // Make a PATCH request to update the user data
        const response = await axios.patch(`/therapy/api/therapists/${this.therapist.id}/`, this.therapist);
        this.therapist = response.data;
        
        this.showMessage= true;
          this.message="Your data updated successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showMessage= false;
          }, 2000);
        } catch (error) {
          this.showMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showMessage= false;
          }, 2000);
        }
    },
    async emailChangeRequest(){
      let payload={
        "email": this.therapist.user.email
      };
      try {
        // Make a PATCH request to update the user data
        await axios.post('/auth/users/reset_email/', payload);
        
        this.showMessage= true;
          this.message="Check your inbox for further instructions."
          this.messageType= 'success'
          setTimeout(() => {
            this.showMessage= false;
          }, 5000);
        } catch (error) {
          this.showMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showMessage= false;
          }, 4000);
        }
    },
    async deleteAccount(){
      this.showPopup= false;
      let finalPayload={
        "password": this.current_password,
        "email": this.therapist.user.email,
        "username": this.therapist.user.username,
      }
      try {
        // Make a DELETE request to remove the file from the generalDocs API
        await axios.delete(`/auth/users/${this.therapist.user.id}/`, {data: finalPayload});
        this.showMessage= true;
        this.message="Account is removed successfully."
        this.messageType= 'warning'
        setTimeout(() => {
          this.showMessage= false;
          this.clearTokens().then(() => {
            this.$router.push({ name: 'Sign In' });
        }, 2000);})
      } catch (error) {
        console.log(error);
        this.showMessage= true;
        this.message="Error: "+ Object.values(error.response.data)
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      }
    },
    validatePhoneNumber() {
      try {
        
        const phoneNumberUtil = parsePhoneNumberFromString(this.therapist.phone_number);
        if (phoneNumberUtil && phoneNumberUtil.isValid()) {
          this.isValidPhoneNumber = true;
          this.phoneNumberInfo = {
            countryCode: phoneNumberUtil.countryCallingCode,
            nationalNumber: phoneNumberUtil.nationalNumber
          };
        } else {
          this.isValidPhoneNumber = false;
          this.phoneNumberInfo = null;
        }
      } catch (error) {
        console.error('Error validating phone number:', error);
        this.isValidPhoneNumber = false;
        this.phoneNumberInfo = null;
      }
    },
    async fetchCities() {
      if (this.therapist.address.length > 2) { // Adjust the minimum query length as needed
        try {
          const response = await axios.get('https://nominatim.openstreetmap.org/search', {
            params: {
              q: this.therapist.address,
              format: 'json',
              limit: 3 // Limit the number of results
            }
          });
          this.cities = response.data;
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        this.cities = [];
      }
    },
    selectCity(city) {
      this.therapist.address = city.display_name;
      this.cities = []; // Hide the dropdown list
    },
  },
  computed: {
    ...mapGetters(['getToken', 'getRToken']),
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
};
</script>
