<template>
  
  <div class="card h-100">
    <div class="p-3 pb-0 card-header card-hover">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <transition-group name="fade">
          <h6 v-if="smart" class="mb-0"><i class="fa fa-hand-sparkles"></i>  Let's get smart</h6>
          </transition-group>
        </div>
        <div class="col-md-4 text-end">
          <transition name="fade">
            <sof-button v-if="back" size="sm" @click="goBack" class="button-enter button-leave">
              <i
                class="text-sm fas fa-backward" style="color: white;"
              ></i>
            </sof-button>
          </transition>
        </div>
      </div>
    </div>
    <transition-group name="fade">
    <div v-if="buttons" class="p-3 card-body text-center button-groups">
      
      <hr class="horizontal gray-light" />

      <sof-button class="mb-3" style="width: 100%; border-radius: 1rem;" @click="article">
        Recommend an article    <i class="fa fa-paperclip"></i>
      </sof-button>

      
      <sof-button class="mb-3" style="width: 100%; border-radius: 1rem;" @click="book">
        Recommend a book <i class="fa fa-book"></i>
      </sof-button>
      
      <sof-button  class="mb-3" style="width: 100%; border-radius: 1rem;" @click="video">
        Recommend a video <i class="fa fa-youtube"></i>
      </sof-button>
      
    </div>
  </transition-group>
  <div v-for="section in phase.sections" :key="section">
    <!-- Recommendation -->
    <transition-group name="fade">
      <div v-if="bookRecom" class="video-card rounded" @mouseenter="isHovering = true" @mouseleave="isHovering = false">
        <div class="card-body" :class="{ 'hovered': isHovering }">
          <h6 class="card-title">{{ phase.section.type =='book' ? phase.section.content : ''}}</h6>
          <p class="card-text"></p>
          <div class="embed-responsive embed-responsive-16by9">
            <a type="button" class="btn btn-outline-warning" :href="googleSearchURL" target="_blank">
              learn more
            </a>
          </div>
        </div>
      </div>
    </transition-group>

  </div>


  </div>
</template>

<script>
import SofButton from "@/components/SoftButton.vue"

export default {
  name: "RecomCard",
  data() {
    return {
      back: false,
      video_data: {
          title: "What is Reality Therapy?",
          description: "",
          videoSrc: "https://www.youtube.com/embed/OOo75EyKUC4?si=nn6JPF3kou9Ju_Yp"
        },
      isHovering: false,
      videoRecom: false,
      transitionLock: false,
      buttons: true,
      smart: true,
      chosenType: '',
      searchContent: '',
    }
  },
  components: {
    SofButton
  },
  methods: {
    article (){
      this.buttons = false;
      this.smart = false;
      setTimeout(() => this.back = !this.back, 500);
      setTimeout(() => this.videoRecom = !this.videoRecom, 500);
    },
    book (){
      this.buttons = false;
      this.smart = false;
      setTimeout(() => this.back = !this.back, 500);
      setTimeout(() => this.videoRecom = !this.videoRecom, 500);
    },
    goBack(){
      this.videoRecom = false;
      setTimeout(() => this.back = !this.back, 500);
      setTimeout(() => {this.buttons = true; this.smart =true}, 500);
    },
    video(){
      this.buttons = false;
      this.smart = false;
      setTimeout(() => this.back = !this.back, 500);
      setTimeout(() => this.videoRecom = !this.videoRecom, 500);
      
    }
  },
  computed: {
  googleSearchURL() {
    // Construct the Google search URL with the content
    const query = encodeURIComponent(this.searchContent);
    return `https://www.google.com/search?q=${query}`;
  },
}
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 0;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.video-card {
  transition: box-shadow 0.3s ease;
  border: none;
  border-radius: 0.25rem;
  overflow: hidden;
}

.card:hover {
  box-shadow: 0 0 15px rgba(144, 238, 144, 0.8);
}
</style>
