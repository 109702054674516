/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import axios from 'axios';

// Set up Axios base URL
axios.defaults.baseURL = 'https://therapistory.com';
// axios.defaults.baseURL = 'http://localhost';
// Add CSRF token to Axios headers
function getCSRFToken() {
  const csrfCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('csrftoken='));
  if (csrfCookie) {
      return csrfCookie.split('=')[1];
  }
  return null;
}

axios.defaults.headers.common['X-CSRFToken'] = getCSRFToken();

// Add request interceptor
axios.interceptors.request.use(
  async config => {
    const accessToken = store.getters.getAccessToken; // Get access token from Vuex store
    if (accessToken) {
      config.headers.Authorization = `JWT ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = store.getters.getRefreshToken;
        if (refreshToken) {
          const response = await axios.post('/api/auth/jwt/refresh', { refresh: refreshToken });

          if (response.status === 200) {
            const newAccessToken = response.data.access;
            store.commit('setAccessToken', newAccessToken);

            // Update Authorization header with new access token
            originalRequest.headers.Authorization = `JWT ${newAccessToken}`;

            // Retry the original request with the updated access token
            return axios(originalRequest);
          }
        }
      } catch (refreshError) {
        console.error('Response refresh interceptor error:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);

appInstance.mount("#app");