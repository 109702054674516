<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content bg-index">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
            <div class="row mt-8 justify-content-center">
              <div class="mx-auto text-center col-lg-6">
                <div class="iframe-background">
                  <iframe src="https://lottie.host/embed/78ff6b3c-dbe9-400e-b35d-295446eca00c/DwwRPTYI7M.json"></iframe>
                </div>
                <h1 class="mt-3 mb-2 text-white">Welcome!</h1>
                <p class="text-white text-lead">
                  Signup to be part of the next generation of therapists.
                </p>
              </div>
              <div class="mx-auto col-lg-5">
              <!-- Preloader  -->
              <div class="card z-index-0" v-if="!show">
                <div class="pt-4 text-center card-header" >
                  <div style="justify-content: center;" >
                    <Preloader />
                  </div>
                </div>
              </div>

              <!-- Signup  -->
              <div class="card z-index-0" v-if="!signUpSuccess && show">
                <div class="pt-4 text-center card-header" >
                  <h5>Get started for free</h5>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signUp" >
                    <div class="mb-3">
                      <input type="text" id="username"
                      v-model="client.username"
                      class="form-control"
                      placeholder="Username"
                      required>
                    </div>
                    <div class="mb-3">
                      <input type="email" id="email"
                      v-model="client.email"
                      class="form-control"
                      placeholder="Email"
                      required>
                    </div>
                    <div class="mb-3">
                      <input type="password" id="password"
                      v-model="client.password"
                      class="form-control"
                      placeholder="Password"
                      required>
                    </div>
                    <div v-if="signUpError">
                      <soft-alert color="danger">
                        <strong>Error!</strong> {{signUpError}}
                      </soft-alert>
                    </div>

                    <div class="text-center">
                      <soft-button
                        color="warning"
                        type="submit"
                        full-width
                        variant="gradient"
                        class="my-4 mb-2"
                        >Sign up</soft-button
                      >
                    </div>
                    <p class="text-sm mt-3 mb-0">
                      Already have an account?
                      <router-link
                        :to="{ name: 'Sign In' }"
                        class="text-success font-weight-bolder"
                      >
                        Sign in
                      </router-link>
                    </p>
                  </form>
                </div>
              </div>
              
              <!-- Signup Success  -->
              <div class="card z-index-0" v-if="signUpSuccess && show">
                <div class="pt-4 text-center card-header" >
                  <h5>Activate your account</h5>
                </div>
                <div class="card-body">
                  <div>
                    <soft-alert color="success">
                      <strong>Your Account is Created!</strong>
                    </soft-alert>
                    <div>
                      <label>Please check your inbox and activate your account through the activation link.</label>
                    </div>
                    <div class="row" style="justify-content: center;">
                      <div class="col-lg-5 m-2">
                        <a href="https://gmail.com/" class="btn btn-info m-auto w-100" target="_blank">Gmail</a>
                      </div>
                      <div class="col-lg-5 m-2">
                        <a href="https://outlook.live.com/mail/about/index_en.html" class="btn btn-info m-auto w-100" target="_blank">Outlook</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
    </section>
    <app-footer />
  </main>
  
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';
import { mapMutations, mapGetters } from "vuex";
import SoftAlert from "@/components/SoftAlert.vue";
import Preloader from "./components/Preloader.vue";

export default {
  name: "SignupBasic",
  data() {
    return {
      client: {
          "email": "",
          "username": "",
          "password": "",
          "user_type": "THERAPIST"
      },
      usernames: [],
      signUpError: null,
      signUpSuccess: false,
      show: true
    }
  },
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    SoftAlert,
    Preloader
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async signUp() {
      this.show = false;
      try {
        // Make a POST request to your API endpoint
        const response = await axios.post('/auth/users/', this.client);
          // Redirect to the login page after successful signup
          this.signUpSuccess = true;
        // Handle the response as needed
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error('Error signing up:', error.response.data);
        this.signUpError = Object.values(error.response.data);
      }
      this.show= true;
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getRToken', 'getUser']),
  },
};
</script>
<style scoped>
.bg-index {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00081e;
  /* background-image: url('../assets/img/curved-images/curved14.jpg'); */
  background-size: cover;
  background-position: center;

}
.iframe-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  
}

.iframe-background iframe {
  width: 100%;
  height: 100%;
}
</style>
