<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content bg-index">
    <section>
      <div class="iframe-background">
        <iframe src="https://lottie.host/embed/87c7d1f2-a76e-47dd-b6c5-b34589491d35/7xKHF4zt7Z.json"></iframe>
      </div>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column" v-if="!showForgetPassword">
              <div class="mt-8 card card-plain bg-white">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="signIn">
                    <label>Email</label>
                    <input type="email" id="email"
                      v-model="client.email"
                      class="form-control"
                      placeholder="Email"
                      required>
                    <label>Password</label>
                    <input type="password" id="password"
                      v-model="client.password"
                      class="form-control"
                      placeholder="Password"
                      required>
                      <p class="mx-auto mb-4 text-sm">
                        <button
                          type="button"
                          @click="showForgetPassword = true"
                          class="text-warning text-gradient text-xs font-weight-bold border-0"
                          >Forgot your password?</button>
                      </p>
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >Sign in
                      </soft-button>
                    </div>
                    <div v-if="signInError">
                      <soft-alert color="danger">
                        <strong>Error!</strong> {{signInError}}
                      </soft-alert>
                    </div>
                    <div v-if="signInSuccess">
                      <soft-alert color="success">
                        <strong>Success!</strong> You will be redirected to your Dashboard shortly
                      </soft-alert>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column" v-if="showForgetPassword">
              <div class="mt-8 card card-plain bg-white">
                <div class="pb-0 card-header text-start" v-if="!resetSent">
                  <h3 class="font-weight-bolder">
                    Reset Your Password
                  </h3>
                  <p class="mb-0">Enter your email to receive instruction</p>
                </div>
                <div class="card-body" v-if="!resetSent">
                  <form role="form" class="text-start" @submit.prevent="resetPassword">
                    <label>Email</label>
                    <input type="email" id="email"
                      v-model="payload.email"
                      class="form-control"
                      placeholder="Email"
                      required>
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        type="submit"
                        full-width
                        >Send the Reset Link
                      </soft-button>
                      <p class="mx-auto mb-4 text-sm">
                        <button
                          @click="showForgetPassword = false"
                          class="text-warning text-gradient text-xs font-weight-bold border-0"
                          >Take me back to login page</button>
                      </p>
                    </div>
                  </form>
                </div>
                <div class="card-body mt-2" v-if="showMessage">
                  <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
                    {{message}}
                  </soft-alert>
                </div>
                <div class="card-body" v-if="resetSent">
                  <h3 class="font-weight-bolder text-info text-gradient text-center">
                    Check your inbox for the reset password link
                  </h3>
                  <div class="row" style="justify-content: center;">
                    <div class="col-lg-5 m-2">
                      <a href="https://gmail.com/" class="btn btn-info m-auto w-100" target="_blank">Gmail</a>
                    </div>
                    <div class="col-lg-5 m-2">
                      <a href="https://outlook.live.com/mail/about/index_en.html" class="btn btn-info m-auto w-100" target="_blank">Outlook</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer />
  </main>
  

</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue"
const body = document.getElementsByTagName("body")[0];
import axios from 'axios';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { jwtDecode } from "jwt-decode";

export default {
  name: "SignIn",
  data() {
    return {
      client: {
        email: "",
        password: ""
      },
      signInError: null,
      signInSuccess: false,
      showForgetPassword: false,
      showMessage: false,
      message: '',
      messageType: '',
      payload:{
        email: "",
      },
      resetSent: false,
    }
  },
  components: {
    Navbar,
    AppFooter,
    // SoftSwitch,
    SoftButton,
    SoftAlert
  },
  created() {

    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['setAccessToken', 'setUser', 'setRefreshToken']),
    async signIn() {
        try {
          
          const response = await axios.post('/api/auth/jwt/create', this.client);
          this.setRefreshToken(response.data.refresh);
          this.setUser(jwtDecode(response.data.access).user_id);
          await this.setAccessToken(response.data.access).then(()=>{
             this.signInSuccess = true;
             this.signInError = false;
             setTimeout(() => {this.$router.push({ name: 'Dashboard' });}, 1000);
             
          });
          
        } catch (error) {
          // Handle errors
          
          this.signInError = Object.values(error.response.data);
          setTimeout(() => {
            this.signInError = null
          }, 4000);
          
        }
      },
      async resetPassword(){
        try {
        await axios.post('/auth/users/reset_password/', this.payload);
        this.resetSent= true;
        this.showMessage= true;
        this.message="Password reset instruction is sent for your email."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
          this.$router.push({ name: 'Sign In' });
        }, 2000);

      } catch (error) {
        console.log(error);
        this.showMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 3000);
      }

      },
  },
  computed: {
    ...mapGetters(['getAccessToken', 'getRefreshToken', 'getUser']),
  },
  
};
</script>
<style scoped>
.bg-index {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00081e;
  /* background-image: url('../assets/img/curved-images/curved14.jpg'); */
  background-size: cover;
  background-position: center;

}
.iframe-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;

  
}
.iframe-background iframe {
  width: 100%;
  height: 100%;
}
</style>