<template>
  <!-- The template for the component. -->
  <div :class="completedCount === todos.length ? 'card bg-success bg-gradient': 'card bg-success bg-gradient bg-dark' ">
    <!-- The card component with a background gradient. -->
    <div class="card-body">
      <!-- The card body containing the title and progress indicator. -->
      <div class="d-flex">
        <!-- The title of the card. -->
        <h5 class="mb-0 text-white">Tasks | Main Points | Milestones </h5>
        <!-- The progress indicator. -->
        <div class="ms-auto">
          <!-- The progress indicator text. -->
          <h1 class="mb-0 text-white text-end mt-n2">{{ completedCount === todos.length ? "Congrats": todos.length - completedCount }}</h1>
          <!-- The progress indicator subtext. -->
          <p class="mb-0 text-sm text-white text-end">{{completedCount === todos.length ? "Well done! All is handled.": "remained"}}</p>
        </div>
      </div>
      <!-- The list of todos. -->
      <div class="mt-3">
        <!-- A list item for each todo. -->
        <div v-for="(todo, index) of todos" :key="todo">
          <!-- The form check for each todo. -->
          <div class="form-check mt-1">
            <!-- The checkbox for each todo. -->
            <input
              :id="'todo_' + todo.id"
              v-model="todo.completed"
              class="form-check-input"
              type="checkbox"
              @change="updateTodoStatus(todo.id, todo.completed)"
            />
            <!-- The label for each todo. -->
            <label :for="index" class="custom-control-label mb-0 text-white">{{ todo.description }}
            </label>
          </div>
        </div>
    </div>
    </div>
    <!-- The "Show More" link. -->
    <!-- <a
      href="javascript:;"
      class="py-1 text-center w-100"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title="Show More"
    >
      <i class="text-white fas fa-chevron-down"></i>
    </a> -->
  </div>
</template>

<script>
export default {
  name: "TodoCard",
  // The component data.
  data() {
    return {
      done:[],
      checked: [],
    };
  },
  components: {
    
  },
  // The component properties.
  props: {
    todos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateTodoStatus(id, value){
      this.$emit('status-update', id, value)
    },
  },
  // The computed properties.
  computed: {
    completedCount() {
      // Count the number of todos with completed set to true.
      return this.todos.filter(todo => todo.completed).length;
    },
  },
};
</script>

<style scoped>
.card {
  transform: scale(1.0) ;
  transition: transform 0.25s ease-in-out;
}
.card:hover {
  transform: scale(1.05) ;
  transition: transform 0.5s ease-in-out;
}
</style>