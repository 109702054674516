<template>
  <div class="border card h-100">
    <div class="text-center card-body d-flex flex-column justify-content-center">
      <a>
        <i class="mb-1 text-sm text-secondary" :class="`fa fa-${icon}`" aria-hidden="true"></i>
        <input class="btn" ref="fileInput" type="file" style="display: none" @change="handleFileUpload">
      </a>
      <input v-model="fileName" type="text" class="form-control mt-2 mb-2" placeholder="Choose a descriptive name for the file">
      <button class="btn btn-outline-success" @click="openFileInput">Upload</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileUploadCard",
  props: {
    title: {
      type: Object,
      default: () => ({}),
      validator(value) {
        return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value.variant);
      },
    },
    icon: {
      type: String,
      default: "file",
    },
    url: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "Upload File",
    },
  },
  data() {
    return {
      fileName: '',
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.$emit("file-selected",  file, this.fileName );
      this.fileName = '';
      this.clearFileInput();
    },
    clearFileInput() {
      // Reset the value of the file input element
      this.$refs.fileInput.value = '';
    },
  },
};
</script>
