<template>
  <div class="border card h-100">
    <div
      class="text-center card-body d-flex flex-column justify-content-center"
    >
      <a :href="url">
        <i
          :class="`fa fa-${icon} mb-1 text-sm text-${color}`"
          aria-hidden="true"
        ></i>
        <component :is="title.variant" :class="`text-${color}`">{{
          title.text
        }}</component>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaceHolderCard",
  props: {
    title: {
      type: Object,
      default: () => {},
      variant: {
        validator(value) {
          return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value);
        },
      },
      text: String,
    },
    icon: {
      type: String,
      default: "plus",
    },
    url: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: 'secondary'
    }
  }
};
</script>
