import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Clients from "@/views/Clients.vue";
import Journeys from "@/views/Journeys.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Sessions from "@/views/Sessions.vue";
import NewJourney from "@/views/NewJourney.vue";
import NewSession from "@/views/NewSession.vue";
import JourneyPhases from "@/views/JourneyPhases.vue"
import SessionView from "@/views/SessionView.vue";
import JourneyView from "@/views/JourneyView.vue";
import EditJourney from "@/views/EditJourney.vue";
import NewClient from "@/views/NewClient.vue";
import store from '@/store';
import UserProfileEdit from "../views/UserProfileEdit.vue";
import UserProfile from "../views/UserProfile.vue";
import ClientProfileEdit from '@/views/ClientProfileEdit.vue';
import JourneyAssign from '@/views/JourneyAssign.vue';
import Index from '@/views/Index.vue';
import Activation from '@/views/Activation.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import EmailReset from '@/views/EmailReset.vue';
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: "/",
    name: "/",
    component: Index,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true } 
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/:id",
    name: "Client Profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/:id/edit",
    name: "Edit Client Profile",
    component: ClientProfileEdit,
    meta: { requiresAuth: true }
  },
  {
    path: "/clients/new",
    name: "New Client",
    component: NewClient,
    meta: { requiresAuth: true }
  },
  {
    path: "/journey",
    name: "Journeys",
    component: Journeys,
    meta: { requiresAuth: true }
  },
  {
    path: "/journey/new",
    name: "New Journey",
    component: NewJourney,
    meta: { requiresAuth: true }
  },
  {
    path: "/journey/assign",
    name: "Assign Journey",
    component: JourneyAssign,
    meta: { requiresAuth: true }
  },
  {
    path: "/journeys/:id",
    name: "Journey View",
    component: JourneyView,
    meta: { requiresAuth: true }
  },
  {
    path: "/journeys/:id/edit",
    name: "Edit Journey",
    component: EditJourney,
    meta: { requiresAuth: true }
  },
  {
    path: "/journey/id/phases",
    name: "Phases of Journey",
    component: JourneyPhases,
    meta: { requiresAuth: true }
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: { requiresAuth: true }
  },
  {
    path: "/session/:id/",
    name: "Session View",
    component: SessionView,
    meta: { requiresAuth: true }
  },
  {
    path: "/session/new",
    name: "New Session",
    component: NewSession,
    meta: { requiresAuth: true }
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/:id/edit",
    name: "Edit Profile",
    component: UserProfileEdit,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/:id/",
    name: "Profile",
    component: UserProfile,
    meta: { requiresAuth: true }
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
    meta: { requiresAuth: true }
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/activate/:uid/:token",
    name: "Activation",
    component: Activation,
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "Password Reset",
    component: PasswordReset,
  },
  {
    path: "/email/reset/confirm/:uid/:token",
    name: "Email Reset",
    component: EmailReset,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const token = store.getters.getAccessToken; // Assuming this getter returns the user's access token

  // If the user is already signed in and tries to access the sign-in or sign-up page, redirect to the dashboard
  if (token !== null && (to.name === 'Sign In' || to.name === 'Sign Up')) {
    next({ name: 'Dashboard' });
  } 
  // If the route requires authentication and the user is not authenticated, redirect to the login page
  else if (to.meta.requiresAuth && token === null) {
    next({ name: 'Sign In' });
  } else {
    next(); // Proceed to the next hook
  }
});

export default router;
